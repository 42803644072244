// File: routes_new_auth_password.tsx
//

import {Route} from "react-router-dom";
import {Routes} from 'react-router-dom';
import {
    USE_ROUTES_ECOMM_AUTH, USE_ROUTES_ECOMM_TOKEN, USE_ROUTES_OLDPUB } from '../config/config';
import { TimedOut } from "../pages_other/timed_out";
import { RoutesAdmin } from "./routes_admin";
import { ECommAuthRoutes } from './routes_ecomm_auth';
import { ECommTokenRoutes } from './routes_ecomm_token';
import { RoutesOldPub } from "./routes_oldpub";
import { TIMED_OUT_ENDING_URL } from '../extlinks/portal_comm_types';
    
interface PageProps {
}

export function AppRoutes(_props:PageProps) : React.ReactElement {

    
    return (
	<Routes>

	{/*
	  <Route  path='/'
		  element={<SignIn name={"Sign In"}/>}
          />
	*/}

	  <Route path={TIMED_OUT_ENDING_URL} element={<TimedOut />} />	  	    

	  { USE_ROUTES_OLDPUB && RoutesOldPub() }
	
	  { USE_ROUTES_ECOMM_AUTH && ECommAuthRoutes() }
	  
	  { USE_ROUTES_ECOMM_TOKEN && ECommTokenRoutes() }	  
	  
	  {RoutesAdmin()}

	</Routes>);
}    

