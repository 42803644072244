// File: private_landing.tsx
//

import * as React from 'react';
import { NavLink, useNavigate, useParams} from 'react-router-dom';
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Copyright} from '../../utils_common/copyright';
/*
import { TDBAppLogoVeryLarge } from '../../utils_common/tdb_large_logo';
import { AlertDialog, AlertDialog2 } from '../../utils_common/alert_dialog';
import { getAuth  } from 'firebase/auth';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import { useAuthStatus } from '../../auth_provider/use_auth';
import { MsgStatus } from '../../extlinks/portal_comm_types';
import { getECommAuthServerComm } from '../utils/ecomm_auth_server_comm';
//import { ECOMM_AUTH_TESTING } from '../../config/config';
import { MsgAlertDialog } from '../../utils_common/alert_dialog';
*/
import { ECOMM_AUTH } from '../../routes/paths';
import { url_params_is_ok } from '../utils/check_url_params';

const TAG='private_landing.tsx: ';
//const DEBUG=true;

const TESTING=true;

const theme = createTheme();

export function ECommAuthPrivateLanding() : React.ReactElement {

    const [urlToken, setUrlToken] =  React.useState<string|null>(null);
    const params = useParams();
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ msg, setMsg ] = React.useState<string>('');
    
    React.useEffect( ()=>{
	if(params.url_params === undefined) {
	    if(TESTING) {
		setMsg('params.url_params is undefined')
	    } else {
		navigate(ECOMM_AUTH.NOT_FOUND_URL);
	    }	    
	}
	if(params.url_params !== undefined) {
	    console.log('url_token=' +params.url_params);
	    url_params_is_ok({url_params : params.url_params}).then((result)=> {
		if( ! result) {
		    console.error(TAG+'url_params_is_ok: result==false');
		    if(TESTING) {
			setMsg('bad url_params')
		    } else {
			navigate(ECOMM_AUTH.NOT_FOUND_URL);
		    }
		}  else {
		    if(params.url_params != null) {
			setUrlToken(params.url_params);
		    }
		}
	    }).catch((error)=>{});
	}

    },[params, params.url_params])
    
    
    return (<>

    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

	{ urlToken != null &&
	(<Box sx={{mt:4}} style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Typography component="h1" variant="h5">	  
	    For Testing Purposes:
            </Typography>
	    
          <NavLink to={ECOMM_AUTH.LICENSE_INFO_URL + '/' + urlToken}>
	  License 
          </NavLink>
	
          <NavLink to={ECOMM_AUTH.MOBUPGRADE_LAUNCH_POPUP_URL + '/' + urlToken}>
	    Mobile Upgrade
          </NavLink>	  	
  	</Box>)
	}

        <Copyright sx={{ mt: 5, mb: 4 }} />
      </Container>
      
    </ThemeProvider>	
    </>);
}


