// File: instruct_install.tsx
//

import * as React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Copyright } from '../../utils_common/copyright';
import { TDBAppLogoVeryLarge } from '../../utils_common/tdb_large_logo';
import { ECOMM_AUTH } from '../../routes/paths';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const TAG='instruct_install.tsx: ';

const theme = createTheme();

export function ECommAuthInstructInstall(_props:{}) : React.ReactElement {

    const params = useParams();

    const [ displayDesktop, setDisplayDesktop ] = React.useState<boolean>(true);
    const [ displayMobile, setDisplayMobile ] = React.useState<boolean>(true);

    React.useEffect(()=>{
	if(params.show !== undefined) {
	    const ws = params.show.split('&');
	    if( ! ws.includes('d')) setDisplayDesktop(false);
	    if( ! ws.includes('m')) setDisplayMobile(false);	    
	}
    },[]);

    return (
<ThemeProvider theme={theme}>
  <CssBaseline/>   
  <Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>	  

     <Box sx={{mt:3,mb:1}}
	style={{display:'flex', flexDirection:'column', alignItems:'center'}}>    
       <Typography align='center' sx={{mt:4}}>
          <TDBAppLogoVeryLarge/>
       </Typography>
     </Box>

     {displayDesktop &&	
     <Box sx={{mt:4}}
	style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
       <NavLink to={ECOMM_AUTH.DESKTOP_INSTALL_INSTRUCT_URL}>
          <Typography variant='h6'>
          Desktop App Install Instructions
          </Typography>	
       </NavLink>
     </Box>
     }

     {displayMobile &&		
     <Box sx={{mt:4}}
	style={{display:'flex', flexDirection:'column', alignItems:'center'}}>    
       <NavLink to={ECOMM_AUTH.MOBILE_INSTALL_INSTRUCT_URL}>
          <Typography variant='h6'>      
          Mobile App Install Instructions
          </Typography>		
       </NavLink>
    </Box>
     }
      
    <Copyright sx={{ mt: 5, mb: 4 }} />

  </Box>
 </ThemeProvider>	
    );
}
