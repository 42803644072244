// File: list_mobupgrade_search_results.tsx
//

import * as React from 'react';
import { MainLayout} from '../utils_admin/main_layout';
import { useAdminPagesContext } from '../utils_admin/admin_pages_provider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import { MobUpgradeableLicensesTable } from '../utils_admin/mobupgrade_invites/mobupgradeable_licenses_table';
import { GetMobUpgradeableLicenses} from '../../extlinks/portal_comm_types';
import { useLocationState } from '../../utils_common/use_location_state';
import { LOGGER} from '../../utils_common/logger';
import { RowFilter, NONE_ROW_FILTER } from '../utils_admin/mobupgrade_invites/select_filter_rows';
import { GoBackToLicensesButton }  from '../utils_admin/mobupgrade_invites/go_back_to_licenses_button';
import { SearchLicensesResultState } from '../utils_admin/mobupgrade_invites/licenses_loc_states';

//const TAG='list_mobupgrade_search_results.tsx';
//const DEBUG=false;

type LicenseInfo = GetMobUpgradeableLicenses.ParentLicenseInfo;

const theme = createTheme();

export function ListMobUpgradeSearchResults() : React.ReactElement {

    const cnt = React.useRef<number>(0);

    const [ rowFilter, setRowFilter ] = React.useState<RowFilter>(NONE_ROW_FILTER);
    
    React.useEffect( ()=> {
	setRowFilter(NONE_ROW_FILTER);
	if(cnt.current===0) {
	    LOGGER.log_event('LIST LICENSES EMAIL SEARCH','list_licenses_email_search.tsx');
	}
	cnt.current += 1;        
    }, [])

    const [ numPerPage, setNumPerPage ] = React.useState<number>(100);
    
    const loc_state = useLocationState<SearchLicensesResultState>();

    const mainStateContext = useAdminPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;
    
    const [userInfos, setUserInfos ] = React.useState<LicenseInfo[]|null>(null);    
    
    React.useEffect(()=> {
	if(loc_state!=null && loc_state.matching_licenses !=null && loc_state.matching_licenses.length>0) {
	    //setUserInfos(loc_state.matching_licenses.filter((x)=>rowFilter.filter(x)));
	    setUserInfos(loc_state.matching_licenses);
	}
    },[])

    return (
	<ThemeProvider theme={theme}>	
	    <CssBaseline />	      
	    <MainLayout title='Admin Console - Display Licenses'>

              <GoBackToLicensesButton backState={loc_state.back_state}/>
	      
	      <>
		{/* change in pageNum causes table to automatically re-render */}
	      {userInfos &&  <MobUpgradeableLicensesTable pageNum={null}
							  setPageNum={()=>{}}
							  split_pages={false}
							  numPerPage={numPerPage}
							  setNumPerPage={setNumPerPage}
							  rowFilter={rowFilter}
							  setRowFilter={setRowFilter}
							  onFilterChange={()=>{}}
							  allLicenseInfos={[...userInfos]}
							  filteredLicenseInfos={[...userInfos]}/>
	      }	      
	      </>

	  <Backdrop invisible={true} open={mainPagesIsLoading}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>
	      
	    </MainLayout>

	</ThemeProvider>
  );
    
}
