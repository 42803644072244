// File: email_link_landing.tsx
//

import * as React from 'react';
import { signInWithCustomToken } from 'firebase/auth'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright} from '../../utils_common/copyright';
import { TDBAppLogoVeryLarge } from '../../utils_common/tdb_large_logo';
import { AlertDialog, AlertDialog2 } from '../../utils_common/alert_dialog';
import { getAuth  } from 'firebase/auth';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import { useAuthStatus } from '../../auth_provider/use_auth';
import { MsgStatus } from '../../extlinks/portal_comm_types';
import { getECommAuthServerComm } from '../utils/ecomm_auth_server_comm';
import {  useParams, useNavigate } from 'react-router-dom';
import { assertUnreachable } from '../../utils_common/assert_unreachable';
import { url_params_is_ok } from '../utils/check_url_params';
import { MsgAlertDialog } from '../../utils_common/alert_dialog';
import { ECOMM_AUTH } from '../../routes/paths';

const TAG='email_link_landing.tsx: ';
const DEBUG=true;

const theme = createTheme();

const TESTING=false;

//const TEST_URL=ECOMM_AUTH.LICENSE_INFO_URL;
//const TEST_URL=ECOMM_AUTH.MOBUPGRADE_LAUNCH_POPUP_URL;

export function ECommAuthEmailLinkLanding() : React.ReactElement {

    console.log(TAG+'entering ECommAuthEmailLinkLanding');

    const [ msg, setMsg ] = React.useState<string>('');
    const [ alertMsg, setAlertMsg ] = React.useState<string>('');
    const [ loggedIn, setLoggedIn ] =React.useState<boolean>(false);
    const regenUrlParams = React.useRef<string|null>(null);
    
    const url_token =  React.useRef<string|null>(null);
    const params = useParams();
    const navigate = useNavigate();
    
    React.useEffect( ()=>{
	if(params.url_params === undefined) {
	    if(TESTING) {
		setMsg('params.url_params is undefined')
	    } else {
		navigate(ECOMM_AUTH.NOT_FOUND_URL);
	    }	    
	}
	if(url_token.current == null) {
	    if(params.url_params !== undefined) {
		url_token.current = params.url_params;
		console.log('url_token.current=' + url_token.current);
		url_params_is_ok({url_params : params.url_params}).then((result)=> {
		    if( ! result) {
			console.error(TAG+'url_params_is_ok: result==false');
			if(TESTING) {
			    setMsg('bad url_params')
			} else {
			    navigate(ECOMM_AUTH.NOT_FOUND_URL);
			}
		    }
		}).catch((error)=>{});
		
	    }
	}

    },[])
    
    const auth = getAuth();

    const [ email, setEmail ] = React.useState<string>('');
    
    const [ spinner, setSpinner ] = React.useState<boolean>(false);

    const handleSubmitImpl = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {

	const tag = TAG+'handleSubmitImpl: ';
	
        setMsg('');

	if(url_token.current==null) {
	    console.error(TAG+'url_token.current==null');
	    if( TESTING) {
		setMsg('url_params==null');
	    } else {
		navigate(ECOMM_AUTH.NOT_FOUND_URL);
	    }
	    return;
	}

	if(email.trim() === '') {
	    setMsg('Need to input email');
	    return;
	}

	// already logged in
	if(loggedIn) {
	    if(TESTING) {
		setMsg('Already logged in');
	    } else {
		const ending_url = regenUrlParams.current == null ? '' :  '/' +regenUrlParams.current;
		navigate(ECOMM_AUTH.PRIVATE_LANDING_URL+ending_url);		
	    }
	    return;
	}
	
	const server = getECommAuthServerComm();
	
	setSpinner(true);
	
	const result =
	    await server.handle_ecomm_auth_get_signin_token_from_url_token( { url_token:url_token.current,
	    email:email.trim() });
		
	setSpinner(false);

	if(result.error_status != null) {
	    console.error(tag+'result.error_status=' +result.error_status);
	    switch(result.error_status) {
		    
		case 'ERROR_CREATING_ACCT' :
		    setMsg('Error creating account.');
		    return;
		case 'INVALID_EMAIL':
		    setMsg('Invalid email.');
		    return
		case 'EMAIL_ALREADY_IN_USE':
		    setMsg('Email already in use.');		    
		    return;
		case 'EMAIL_NOT_ELIGIBLE':
		    setMsg('Email not eligible for new account.');		    		    
		    return;
		case 'INTERNAL_ERROR':
		    setMsg('Something went wrong.');
		    return;		    
		case 'SERVER_ERROR':

		    return;
		case 'SERVER_INTERNAL_ERROR':
		    setMsg('Server Error.');
		    return;
		case 'EMAIL_IS_FOR_ADMIN_USER':
		    setAlertMsg('Admin user is not allowed to login by passwordless email link. Will redirect to creating account page.')
		    return;		    
		case 'ERROR_REGEN_URL_TOKEN':
		    setMsg('Internal error.');
		    return;
		case 'ERROR_RESETTING_ADMIN_ROLE':
		    setMsg('Internal error.');
		    return;		    
		case 'INVALID_URL_TOKEN':
		    setMsg('Email link has expired or is invalid.');
		    return;		    
	    }
	    assertUnreachable(result.error_status);
	}

	if(result.status === MsgStatus.ERROR) {
	    setMsg('Internal Error. (code=0)');
	    console.error(tag+'result.status === MsgStatus.ERROR');
	    return;	
	}

	if(result.signin_custom_token==null) {
	    setMsg('Internal Error. (code=1)');	    
	    console.error(tag+'result.signin_custom_token==null');
	    return;
	}

	regenUrlParams.current = result.regen_url_params;
	
	const custom_token = result.signin_custom_token;
	
	const user_cred = await signInWithCustomToken(auth, custom_token);

	const ending_url = result.regen_url_params == null ? '' :  '/' + result.regen_url_params;
	
	if(user_cred ==null) {
	    console.error(tag+'user_cred==null');	    
	    if(TESTING) {	    
		setMsg('user_cred==null');
	    } else {
		navigate(ECOMM_AUTH.PRIVATE_LANDING_URL+ending_url);		
	    }
	} else {
	    setLoggedIn(true);
	    if(TESTING) {
	    } else {
		console.info(tag+'user_cred != null');
		navigate(ECOMM_AUTH.PRIVATE_LANDING_URL+ending_url);
	    }
	}
	
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
	event.preventDefault();
	handleSubmitImpl(event).then(()=>{
	}).catch((error)=>{
	    console.error(TAG+'handleSubmit: error=' + error);
	    setMsg('Internal Error. (code=2)')
	});
    }

    const dialog_is_open = () :boolean=> {
       return msg !== '';
    }

    const close_dialog = () : void => {
	setMsg('');
    }

    const dialog_props = {
	open : dialog_is_open,
	handle_close : close_dialog,
	handle_choice1 :close_dialog,
	handle_choice2 :close_dialog,

	title : msg,
	text : '',
	choice1 : 'OK',
	choice2 : ''
    }
    
    const { user } = useAuthStatus();

    React.useEffect(()=>{
	if(user != null && user.emailVerified && url_token.current != null) {
	    setLoggedIn(true);
	    if( TESTING) {
		setMsg('PT1: login successful');
	    } else {
		navigate(ECOMM_AUTH.PRIVATE_LANDING_URL+'/' + url_token.current);
	    }
	}
        
    },[ user, user?.emailVerified]);

    const onAlertClose = ():void => {
	if(!TESTING) {
	    if(url_token.current != null) {
		navigate(ECOMM_AUTH.PRIVATE_LANDING_URL+'/' + url_token.current)
	    }
	}
    }

    return (

    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

	<Typography align='center' sx={{mb:1}}>
         <TDBAppLogoVeryLarge/>
	</Typography>
	 
          <Typography component="h1" variant="h5">
            For security purposes, enter your email again.
          </Typography>
	  
          <Box component="form"
	       onSubmit={(ev: React.FormEvent<HTMLFormElement>)=>handleSubmit(ev)}
	       noValidate sx={{ mt: 1 }}>
	    
            <TextField
		margin="normal"
		required
		fullWidth
		id="email"
		label="Email Address"
		name="email"
		autoComplete="email"
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setMsg('');
		    setEmail(e.target.value.trim().toLowerCase())}}	    
            />
	    
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 0 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
	
	<AlertDialog {...dialog_props} />

        <Copyright sx={{ mt: 5, mb: 4 }} />
      </Container>

        <MsgAlertDialog msg={alertMsg} setMsg={setAlertMsg}
			on_close={():void=>{onAlertClose()}} />
      
	  <Backdrop invisible={true} open={spinner}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>

    </ThemeProvider>

  );
}

