// File: list_licenses_email_search_results.tsx
//

import * as React from 'react';
//import { useNavigate} from 'react-router-dom';
import { MainLayout} from '../utils_admin/main_layout';
import { useAdminPagesContext } from '../utils_admin/admin_pages_provider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import { AllLicensesTable } from '../utils_admin/display_licenses/all_licenses_table';
import { ListAllLicensesMsg } from '../../extlinks/portal_comm_types';
import { SearchLicensesResultState } from '../utils_admin/display_licenses/licenses_loc_states';
import { LOGGER} from '../../utils_common/logger';
import { useLocationState } from '../../utils_common/use_location_state';
import { GoBackToLicensesButton } from '../utils_admin/display_licenses/go_back_to_licenses_button';
//const TAG='list_license_email_search_results.tsx: ';
//const DEBUG=false;
 
const theme = createTheme();

export function ListLicensesEmailSearchResults() : React.ReactElement {

    const cnt = React.useRef<number>(0);

    if(cnt.current===0) {
	LOGGER.log_event('LIST LICENSES EMAIL SEARCH','list_licenses_email_search.tsx');
    }
    
    cnt.current += 1;

    const loc_state = useLocationState<SearchLicensesResultState>();

    const mainStateContext = useAdminPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;

    const [userInfos, setUserInfos ] = 
	React.useState<ListAllLicensesMsg.UserLicense[]|null>(null);    
    
    // TODO: need refresh button to account for changes in license from last time
    React.useEffect(()=> {
	if(loc_state !=null && loc_state.matching_licenses!=null && loc_state.matching_licenses.length>0) {
	    setUserInfos(loc_state.matching_licenses);
	}
    },[])

    return (
	<ThemeProvider theme={theme}>	
	    <CssBaseline />	      
	    <MainLayout title='Admin Console - Display Licenses'>

	      <>
	      {	loc_state!=null && loc_state.back_state !== undefined &&
		  (<GoBackToLicensesButton backState={loc_state.back_state}/>)
	      }</>
	      
	      <>
		{/* change in pageNum causes table to automatically re-render */}
	      {userInfos &&  <AllLicensesTable page_num={null}
					       setPageNum={()=>{}}
					       split_pages={false}
					       current_user_admin_role={mainStateContext.current_user_admin_role}
					       allUserLicenses={userInfos}
					       filteredUserLicenses={userInfos}/>}	      
	      </>

	  <Backdrop invisible={true} open={mainPagesIsLoading}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>
	      
	    </MainLayout>

	</ThemeProvider>
  );
    
}
