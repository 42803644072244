// File: edit_admin_list.tsx
//

import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import { MainLayout} from '../utils_admin/main_layout';
import { useAdminPagesContext } from '../utils_admin/admin_pages_provider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop  from '@mui/material/Backdrop';
import {MsgAlertDialog} from '../../utils_common/alert_dialog';
import  CircularProgress  from '@mui/material/CircularProgress';
import { get_session_token, LOGGER} from '../../utils_common/logger';
import { getAdminServerComm } from '../utils_admin/admin_server_comm';
import {  ListAdmins, MsgStatus } from '../../extlinks/portal_comm_types';
import { ListAdminsTable } from '../utils_admin/list_admins_table';
import { AdminRole } from '../../extlinks/portal_comm_types';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { OKDialog } from '../utils_admin/ok_cancel_dialog';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import { get_on_local_host } from '../../config/firebase_config';
import { ADMIN } from '../../routes/paths';

const TAG='edit_admin_list.tsx: ';
//const DEBUG=false;

const theme = createTheme();

const ROLE_EXPLANATION=(<>
 <Box sx={{mt:3,display:'flex', flexDirection:'column', alignItems:'center'}}>
     <Box>
	<table>
	  <tbody>
             <tr><td>VIEWER</td><td>can only view admin-privileged information</td></tr>
             <tr><td>EDITOR</td><td>can view and edit admin-privileged information</td></tr>
             <tr><td>MANAGER</td><td>can view and edit admin-privileged information;</td></tr>
             <tr><td></td><td>can add/remove admin users; change admin roles</td></tr>
	     <tr><td>MANAGER*&nbsp;&nbsp;</td><td>special admin with MANAGER role whose account cannot</td></tr>
	     <tr><td></td><td>be edited or removed (except inside the server code)</td></tr>
	  </tbody>	     
         </table>
       </Box>

 </Box>          
    </>
);

type AdminInfo = ListAdmins.AdminInfo;

function check_email(email:string):boolean {
    if(email.indexOf(' ') >= 0) return false;
    if(email.indexOf('..') >= 0) return false;    
    const ws = email.split('@');
    if(ws.length!==2) return false;
    if(ws[0].trim()==='' || ws[1].trim()==='') return false;
    let xs = ws[1].split('.');
    if(xs.length <= 1) return false;
    for(let x of xs) {
	if(x.trim()==='') return false;
    }
    return true;
}

function AddNewAdmin(props:{
    refresh:()=>void }) : React.ReactElement {

	const [ email, setEmail] = React.useState<string>('');
	const [ role, setRole] = React.useState<AdminRole>(AdminRole.NONE);
	const [ sendInvite, setSendInvite ] = React.useState<boolean>(true);
	
	const [ msg, setMsg ] = React.useState<string>('');

	const add_admin = ():void=> {
	    setMsg('');	    	    
	    if(email.trim()==='') {
		setMsg('Error: email is not set.');	    	    
		return;
	    }

	    if( ! check_email(email)) {
		setMsg('Error: email is not valid.');	    	    
		return;		
	    }

	    if(role===AdminRole.NONE) {
		setMsg('Error: admin role is not set.');	    	    
		return;
	    }

	    const session_token = get_session_token();
	    const server = getAdminServerComm()
	    server.handle_add_new_admin({
		session_token,
		invited_email:email,
		admin_role:role,
		web_host_is_local:get_on_local_host(),
		send_invite : sendInvite,
	    } ).then(
		(response)=> {
		    setEmail('');
		    setRole(AdminRole.NONE)
		    props.refresh();
		    if(response.status===MsgStatus.ERROR || response.error_msg) {
			if(response.error_msg) {
			    setMsg(response.error_msg);
			    LOGGER.error(TAG+'handle_change_admin_role: server error: '
				+ response.error_msg);			    
			} else {
			    setMsg('Server error!');
			    LOGGER.error(TAG+'handle_change_admin_role: server error');
			}
		    } else {
			setMsg('Success!  Admin user added.');
		    }
		}).catch( (error)=> {
		    setMsg('Server error!');
		    setEmail('');
		    setRole(AdminRole.NONE)		    
		    LOGGER.error(TAG+'handle_change_admin_role: error=' + error);
		    console.error(TAG+'handle_change_admin_role: error=' + error);
		})
	}

	
	const TriggerMenu = () : React.ReactElement => {

	    const popupState = usePopupState({
		variant: 'popover',
		popupId: 'demoMenu'
	    })

	    const set_admin_role = (new_admin_role : AdminRole):void=> {
		setRole(new_admin_role);
		popupState.close();	    	    
	    }

	    return (
		<Box sx={{mr:1,ml:1}}>
		  <Button variant='outlined' 		size="small"
		     {...bindTrigger(popupState)}
		  >
		 {role === AdminRole.NONE ? 'Select Role' : role}
		  </Button>
		  
		  <Menu
		  {...bindMenu(popupState)}
		      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
		      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
		  >
		    <MenuItem onClick={()=>set_admin_role(AdminRole.VIEWER)}>VIEWER</MenuItem>		
		    <MenuItem onClick={()=>set_admin_role(AdminRole.EDITOR)}>EDITOR</MenuItem>
		    <MenuItem onClick={()=>set_admin_role(AdminRole.MANAGER)}>MANAGER</MenuItem>
		  </Menu>
		  
		</Box>
	    )
	}

	const onCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>):void => {
	    if(e.target!=null) {
		setSendInvite(e.target.checked);
	    }
	}

   return(
        <Box component='div'
	    sx={{
		mt:5,
		display: 'flex', flexDirection:'row',
 		alignItems : 'center',
		justifyContent:'center'}}>

            <TextField
		sx={{mr:1}}
		variant='outlined'
		size="small"
		required
		label="Invite Email"
		name=""
		value={email}
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setEmail(e.target.value.trim().toLowerCase())
		}}
            />
	    
               <TriggerMenu/>
	    
	    <Button  onClick={add_admin}
		     size="small" sx={{ml:3, border:'solid'}}>
	      ADD ADMIN USER 
	      </Button>

	      <Typography sx={{ml:4}}>Send email invite</Typography>
	      <Checkbox size='small'  onChange={onCheckboxChange} checked={sendInvite}/>

	      <MsgAlertDialog msg={msg} setMsg={setMsg} />

	</Box>
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SendAdminInviteBar() : React.ReactElement { 

	const [ email, setEmail] = React.useState<string>('');
	const [ msg, setMsg ] = React.useState<string>('');

	const send_invite = ():void=> {
	    setMsg('');	    	    
	    if(email.trim()==='') {
		setMsg('Error: email is not set.');	    	    
		return;
	    }

	    if( ! check_email(email)) {
		setMsg('Error: email is not valid.');	    	    
		return;		
	    }

	    const session_token = get_session_token();
	    const server = getAdminServerComm()
	    server.handle_send_admin_invite({
		session_token,
		invited_email:email,
		web_host_is_local:get_on_local_host(),
	    } ).then(
		(response)=> {
		    setEmail('');
		    if(response.status===MsgStatus.ERROR || response.error_msg) {
			if(response.error_msg) {
			    setMsg(response.error_msg);
			    LOGGER.error(TAG+'handle_change_admin_role: server error: '
				+ response.error_msg);			    
			} else {
			    setMsg('Server error!');
			    LOGGER.error(TAG+'handle_change_admin_role: server error');
			}
		    } else {
			setMsg('Success!  Invite sent.');
		    }
		}).catch( (error)=> {
		    setMsg('Server error!');
		    setEmail('');
		    LOGGER.error(TAG+'handle_send_admin_invite: error=' + error);
		    console.error(TAG+'handle_send_admin_invite: error=' + error);
		})
	}

   return(
        <Box component='div'
	    sx={{
		mt:3, 
		display: 'flex', flexDirection:'row',
 		alignItems : 'center',
		justifyContent:'center'}}>

            <TextField
		sx={{mr:1}}
		variant='outlined'
		size="small"
		required
		label="Invite Email"
		name=""
		value={email}
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setEmail(e.target.value.trim().toLowerCase())
		}}
            />
	    
	    <Button  onClick={send_invite}
		     size="small" sx={{ml:3, border:'solid'}}>
	      SEND INVITE AGAIN
	      </Button>

	      <MsgAlertDialog msg={msg} setMsg={setMsg} />

	</Box>
    )
}

function sendEmailInvite(row : AdminInfo, props:{setMsg :((msg:string)=>void) }) : void {

    const email = row.invited_email;

    const tag = TAG+'sendEmailInvite: ';

    props.setMsg('');
    
    const session_token = get_session_token();
    const server = getAdminServerComm()
	    server.handle_send_admin_invite({
		session_token,
		invited_email:email,
		web_host_is_local:get_on_local_host(),
	    } ).then(
		(response)=> {
		    if(response.status===MsgStatus.ERROR || response.error_msg) {
			if(response.error_msg) {
			    props.setMsg(response.error_msg);
			    LOGGER.error(tag+'server error: '
				+ response.error_msg);			    
			} else {
			    props.setMsg('Server error!');
			    LOGGER.error(tag+'server error');
			}
		    } else {
			props.setMsg('Success!  Invite sent.');
		    }
		}).catch( (error)=> {
		    props.setMsg('Server error!');
		    LOGGER.error(tag+'error=' + error);
		    console.error(tag+'error=' + error);
		})    
}

export function EditAdminList() : React.ReactElement {

    const mainStateContext = useAdminPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;

    const [msg, setMsg ] = React.useState<string>('');
    
    const [ isWaiting,setIsWaiting] = React.useState<boolean>(false);
    
    const server = getAdminServerComm();

    const adminInfosRef = React.useRef<AdminInfo[]|null>(null);

    const [adminInfos, setAdminInfos ] = React.useState<AdminInfo[]|null>(null);    

    const refresh = (force:boolean=false):void => {
	
	if(adminInfosRef.current == null || force) {

	    setIsWaiting(true);
	    const session_token = get_session_token();	
	    server.handle_list_admins({
		session_token,
	    }).then(
		(result:ListAdmins.OutType):void=> {
		    console.log(TAG+'return from server.handle_list_admins');
		    if(result.status === MsgStatus.OK) {
			adminInfosRef.current = result.admin_infos;
			setAdminInfos(adminInfosRef.current);
		    } else {
			if(result.error_msg) {
			    console.error(TAG+'result.error_ms=' + result.error_msg);
			    LOGGER.error(TAG+'result.error_ms=' + result.error_msg);
			} else {
			    console.error(TAG+'result.status==="ERROR"');
			    LOGGER.error(TAG+'result.status==="ERROR"');
			}
		    }
		    setIsWaiting(false);		    
		}
	    ).catch( ()=> {
		setIsWaiting(false);
	    });
	} else {
	    if(adminInfos==null) setAdminInfos(adminInfosRef.current);	    
	}
    }

    const navigate = useNavigate();

    const cnt = React.useRef<number>(0);

    React.useEffect( ()=> {
	
	if(cnt.current===0) {
	    LOGGER.log_event('EDIT ADMIN LIST','edit_admin_list.tsx');
	}
	
	if(mainStateContext.current_user_admin_role !== AdminRole.MANAGER) {
	    navigate(ADMIN.HOME_URL);
	} else {
	    if(cnt.current===0) refresh();
	}
	
	cnt.current++;
	
    },[])
    
    const row_edit_action = (_row:AdminInfo):void => {
	console.log('HELLO');
    }

    const [ errorMsg , setErrorMsg ] = React.useState<string>('');

    const openOKDialog = ():boolean=> {
	return errorMsg !== '';
    }

    const sendInvite = (row : AdminInfo):void=> {
	sendEmailInvite(row, { setMsg  });
	refresh(true);
    }
    
    return (
	<ThemeProvider theme={theme}>	
	    <CssBaseline />	      
	    <MainLayout title='Admin Console - Edit Admin List'>
	      
	      <>
	      {adminInfos &&
		  (<>
		    <ListAdminsTable adminInfos={adminInfos}
				     sendInvite={sendInvite}
					       row_edit_action={row_edit_action}
					       set_error_msg={setErrorMsg}
					       refresh={()=>refresh(true)}
			      />


	      <Box textAlign="center" sx={{mt:2}}>
	    <Button size="small" sx={{ border:'solid'}} onClick={()=>refresh(true)}>
            REFRESH
	    </Button>	      
	      </Box>

	      <AddNewAdmin refresh={()=>refresh(true)}/>

      {ROLE_EXPLANATION}


         {/*  <SendAdminInviteBar/>	       */}
		  </>)
	      }

	      </>	      

    <>
   { errorMsg.trim() !== '' &&
      ( <Box sx={{mt:2}}>
        <Typography sx={{fontSize:'1.5em'}}
		  align='center'>
	errorMsg
      </Typography>	
      </Box>)
   }
      </>

      <OKDialog
	  open={openOKDialog()}
	  onClose={()=>{setErrorMsg('')}}
	  title={'Error: '+errorMsg}
      />

	  <Backdrop invisible={true} open={mainPagesIsLoading || isWaiting}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>

	  <MsgAlertDialog msg={msg} setMsg={setMsg} />	  

	    </MainLayout>

	</ThemeProvider>
  );
    
}

