// File: prehome.tsx
//

import * as React from 'react';
import { get_session_token, LOGGER} from '../../utils_common/logger';
import { getServerComm } from '../utils/server_comm';
import { MsgStatus, OLDPUB_HOME_URL, OLDPUB_MOBUPGRADE_URL } from '../../extlinks/portal_comm_types';
import { useNavigate } from 'react-router-dom';
import { UseMobUpgradeRoutes } from '../utils/use_mobupgrade_routes';

const TAG='prehome.tsx: ';
const DEBUG=true;

export function PreHome() : React.ReactElement {
    
    const report_login = ():void => {

	console.log(TAG+'entering report_login');
	
	const server = getServerComm();
	const session_token = get_session_token();
	server.handle_report_user_login({ session_token })
	    .then(
		(result)=>{
		    if(result == null || result.status !== MsgStatus.OK) {
			LOGGER.error(TAG+'Error reporting login');
			console.error(TAG+'Error reporting login');			
		    } else {
			// following is not needed because
			// of <MainLayout> and it leads to server timeout
			// during login
			    //mainStateContext.refresh_user_licenses_info();
			if(DEBUG) console.log(TAG+'success: report login to server');
		    }
	    })
	    .catch(
		    (error)=> {
			LOGGER.error(TAG+'Error logging onto server, error='+String(error));
			console.error(TAG+'Error logging onto server, error='+String(error));
	    });
	    
    }

    const navigate = useNavigate();
    
    const redirect = ():void => {
		
	if(UseMobUpgradeRoutes.is_on()) {

	    console.log(TAG+'UseMobUpgradeRoutes.is_on()=true');

	    //navigate(OLDPUB_MOBUPGRADE_URL, {state : { from_signin_page:true }});
	    
	    navigate(OLDPUB_MOBUPGRADE_URL);
	    
	} else {

	    console.log(TAG+'UseMobUpgradeRoutes.is_on()=false');
	    
	    navigate(OLDPUB_HOME_URL,
		{state : { from_signin_page:true }});
	}
    }
    
    const count = React.useRef<number>(0);
    
    React.useEffect( ()=> {
	
	redirect();
	
	if(count.current === 0) {
	    LOGGER.log_event('ENTER_PRE_HOME_PAGE','prehome.tsx');
	    report_login();	    
	}
	count.current++;
    },[]);	
    
    return (<></>)
}

