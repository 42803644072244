// File: routes_admin.tsx
//

import * as React from 'react';
import {Route} from "react-router-dom";
import { AdminRequireAuth } from "../pages_admin/AdminRequireAuth";
import { AdminPagesProvider } from '../pages_admin/utils_admin/admin_pages_provider';
import { AdminHome } from '../pages_admin/auth_pages/admin_home';
import { ListAllLicenses } from "../pages_admin/auth_pages/list_all_licenses";
import { ListLicensesEmailSearchResults } from "../pages_admin/auth_pages/list_licenses_email_search_results";
import { EditLicense } from "../pages_admin/auth_pages/edit_license";
import { EditAdminList } from "../pages_admin/auth_pages/edit_admin_list";
import { SendMobUpgradeInvitesPage } from "../pages_admin/auth_pages/send_mobupgrade_invites";
import { FreeMobileLicenses } from "../pages_admin/auth_pages/free_mobile_licenses";
import { ListMobUpgradeSearchResults } from "../pages_admin/auth_pages/list_mobupgrade_search_results";
import { AdminCannotChangeEmail } from "../pages_pub/portal_pages/admin_cannot_change_email";
import { ChangeEmailVerified } from  "../pages_pub/portal_pages/change_email_verified";
import { PubMainPagesProvider } from '../pages_pub/utils/pub_main_pages_provider';
import { ADMIN } from './paths';
import { AuthProvider } from '../auth_provider/auth_provider';


const TAG='routes_admin.tsx: ';

class GatherPaths {
    private GatherPaths_:undefined;
    readonly paths : string[] = [];
    add(path:string):string {
	this.paths.push(path);
	return path;
    }
}

function get_admin_unprotected(paths:GatherPaths) : React.ReactElement[] {

    return [

	  <Route path={paths.add(ADMIN.CANNOT_CHANGE_EMAIL_URL)}
		 key={-1}
		   element={
		       <PubMainPagesProvider>
			 <AdminCannotChangeEmail/>
		       </PubMainPagesProvider>
		   } />
	    	
    ]
    
}

function get_admin_protected(paths:GatherPaths) : React.ReactElement[] {

    let key=0;
    
    return [

	    <Route path={paths.add(ADMIN.CHANGE_EMAIL_ENDING_URL)}
		   key={key++}
		   element={
		       <PubMainPagesProvider>
			 <ChangeEmailVerified/>
		       </PubMainPagesProvider>} />,
	    
            <Route
		path={paths.add(ADMIN.ADMIN_URL)}
		key={key++}		
		element={
		    <AdminHome/>} />,
	    	    
            <Route
		path={paths.add(ADMIN.HOME_URL)}
		key={key++}				
		element={
		    <AdminHome/>} />,
	    
            <Route 
	      path={paths.add(ADMIN.LIST_ALL_LICENSES_URL)}
		key={key++}				
		element={
		    <ListAllLicenses/>} />	    ,

            <Route 
		   path={paths.add(ADMIN.LIST_LICENSES_EMAIL_SEARCH_URL)}
		key={key++}				
		   element={
			 <ListLicensesEmailSearchResults/>} />,

            <Route 
		   path={paths.add(ADMIN.EDIT_LICENSE_URL)}
		key={key++}				
		   element={
			 <EditLicense/>} />,

            <Route 
		   path={paths.add(ADMIN.FREE_MOBILE_LICENSES_URL)}
		key={key++}				
		   element={
		       <FreeMobileLicenses/>} />,

            <Route 
		   path={paths.add(ADMIN.EDIT_ADMIN_LIST_URL)}
		key={key++}				
		   element={
			 <EditAdminList/>} />,

            <Route 
	      path={paths.add(ADMIN.SEND_MOBUPGRADE_INVITES_URL)}
		key={key++}				
		element={
		    <SendMobUpgradeInvitesPage/>} />,

            <Route 
	      path={paths.add(ADMIN.LIST_MOBUPGRADE_SEARCH_RESULTS_URL)}
		key={key++}				
		element={
		    <ListMobUpgradeSearchResults/>} />  	    ,
	
    ];
}

export function check_admin_routes() : void {

    const paths = new GatherPaths();
    
    get_admin_unprotected(paths);
    
    get_admin_protected(paths);

    let added_paths = paths.paths.sort().join(' ');
    console.log(TAG+'added_paths='+added_paths);
    
    let missing :string[]= [];
    for(let value of Object.values(ADMIN)) {
	//console.log(TAG+'value='+value);
	if( ! (paths.paths.includes(value))) {
	    //console.error(TAG+'missing path='+value);
	    missing.push(value);
	}
    }

    console.log(TAG+'missing_paths='+missing.sort().join(' '));    
    if(missing.length>0) {
	//throw Error(TAG+'check_admin_routes: missing='+missing);
    }
}

export function RoutesAdmin() : React.ReactElement {

    const paths = new GatherPaths();
    
    const admin_unprotected = get_admin_unprotected(paths);
    
    const admin_protected = get_admin_protected(paths);
    
    return (
	<>

	  { admin_unprotected }
	  
	  <Route element={
	      <AuthProvider>
	      <AdminPagesProvider>
		<AdminRequireAuth />
	      </AdminPagesProvider>
	      </AuthProvider>
	  } >

	    <Route path={ADMIN.CHANGE_EMAIL_ENDING_URL}
		   element={
		       <PubMainPagesProvider>
			 <ChangeEmailVerified/>
		       </PubMainPagesProvider>} />
	    
	   { admin_protected }

	</Route>
	</>);
}
