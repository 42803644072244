// File: all_licenses_table.tsx

//

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AdminRole, ListAllLicensesMsg } from '../../../extlinks/portal_comm_types';
import { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_BG_COLOR } from '../utils_config';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { HelpPopup } from '../help_popup';
import { RowFilter } from './select_filter_rows';
import { LicenseSorter } from './select_license_sorter';
import {  EditLicenseState,
//    get_licenses_table_state,
    get_edit_license_state } from './licenses_loc_states';
import { sendLocationState } from '../../../utils_common/use_location_state';
import { ADMIN } from '../../../routes/paths';

const TAG='all_licenses_table.tsx: ';

const NUM_PER_PAGE=100;

const EMAILS_ABBREVS=(
<>
<div>

  <Table sx={{textAlign:'left'}}>
  <TableBody>    
    <tr key='x1'><td style={{paddingRight:'2em'}}>W</td><td>web portal</td></tr>
    <tr key='x2'><td>FS</td><td>fastspring</td></tr>
    <tr key='x3'><td>AS</td><td>activation server</td></tr>
    <tr key='x4'><td>AC</td><td>active campaign</td></tr>
  </TableBody>        
  </Table>
</div>
</>
);

const LICENSE_TYPES_ABBREVS=(
<>
<div>
  <Table>
  <TableBody>        
    <tr key='y1'><td style={{paddingRight:'3em'}}>D</td><td>license allows desktop</td></tr>
    <tr key='y2'><td>M</td><td>license allows mobile</td></tr>
    <tr key='y3'><td style={{verticalAlign:'top'}}>MUE</td><td style={{verticalAlign:'top'}}>license does not allow mobile,<br/> but eligible for mobile upgrade</td></tr>    
    <tr key='y4'><td>MUP</td><td>purchased mobile upgrade</td></tr>
  </TableBody>            
  </Table>
</div>
</>
);

/*
function extract_date(iso_datetime:string) : string {
    if(iso_datetime.indexOf('T')>=0) return iso_datetime.split('T')[0];
    if(iso_datetime.indexOf(' ')>=0) return iso_datetime.split(' ')[0];
    return iso_datetime;
}*/

function get_license_type(row :  ListAllLicensesMsg.UserLicense) : string {
    const x : ListAllLicensesMsg.LicenseType = row.license_type;
    let entries:string[] = [];
    if(row.mobile_upgrade_parent_key != null) {
	entries.push('MUP');
    } else {
	if(x.includes_desktop) entries.push('D');
	if(x.includes_mobile) entries.push('M');
	if(x.includes_upgrade_to_mobile) entries.push('MUE');
	//if(x.has_upgraded_to_mobile) entries.push('IMUP*');
    }
    return entries.join(',');
}

function getwrap(row :  ListAllLicensesMsg.UserLicense) : string {
    if( (row.as_user_id.split(' ').length-1) > 3) return 'wrap'
    //return 'nowrap';
    return 'wrap';
}

function get_emails_entry(row :  ListAllLicensesMsg.UserLicense) : React.ReactElement {

    function set_emails_mp(emails : string[], src:string, mp : Map<string,string[]>) : void {
	for(let x of emails) {
	    if( ! mp.has(x)) mp.set(x,[]);
	    const y = mp.get(x);
	    if(y) mp.set(x, y.concat(src));
	}
    }
    
    const mp = new Map<string,string[]>();
    
    const portal_email = row.portal_email ? row.portal_email: '';
    
    if(portal_email) mp.set(portal_email,['W'])
    
    set_emails_mp(row.as_emails,'AS',mp);
    
    set_emails_mp(row.fs_emails,'FS',mp);
    
    set_emails_mp(row.ac_emails,'AC',mp);

    let lines :string[] = [];

    if(portal_email !== '') {
	for(let [key,value] of mp) {
	    if(key === portal_email) {
		lines.push(key + ' (' + value.join(',') + ')');
		break;
	    }
	}
    }
    
    for(let [key,value] of mp) {
	if(key !== portal_email && key !== '' ) {
	    lines.push(key+ ' (' + value.join(',') + ')');
	}
    }

    let cnt = 0;
    
    return  (<>
	{
	    lines.map( (line) => {
		return (<div key={String(cnt++)}>{line}</div>)
	    })
	}
          </>
    )
}

function PageSelection(props: { current_page_num:number,
    setPageNum:(n:number)=>void,
    rowFilter?:RowFilter,
    licenseSorter? : LicenseSorter,
    allUserLicenses : ListAllLicensesMsg.UserLicense[],
    filteredUserLicenses : ListAllLicensesMsg.UserLicense[]}) :  React.ReactElement {

	const [ pageNums, setPageNums] = React.useState<number[]>([0]);

	React.useEffect( ()=> {
	    const num_all_entries = props.filteredUserLicenses.length;
	    const maxPages = Math.ceil( num_all_entries / NUM_PER_PAGE );
	    const nums : number[] = [];
	    for(let i=0;i<maxPages;i++) nums.push(i);
	    setPageNums(nums);
	    
	},[props.filteredUserLicenses, props.rowFilter]);
	
	let cnt = 0;
	    
    return (<>
	<Box component='div'
	     sx={{mt:2}}
	     style={{display:'flex', width:'100%',
		 flexDirection:'row', 
		 alignItems : 'center', justifyContent:'center'}}>
	  
	<Box component='div'
	     style={{display:'flex', flexWrap:'wrap', maxWidth:'80%',
		 flexDirection:'row', 
		 alignItems : 'center', justifyContent:'center'}}>
	
        { pageNums.map( (n:number)=> {
		//console.log('n='+n);
	     // const page_num :number |null = n;
	      //const state = get_licenses_table_state({ page_num, allUserLicenses: props.allUserLicenses});
		return (
		<Box component="div" key={'page-selection'+cnt++}
		     sx={{pl:1,pr:1,
			 backgroundColor: (n===props.current_page_num ? "lightblue ":"white")}}>

		<Link to='' onClick={()=>props.setPageNum(n)}>
		  		  {String(n+1)}
		</Link>
		</Box>)
	    }
	)
	}

	</Box>
	</Box>	
    </>);
}

//		      onClick={()=>{window.location.reload()}}

type LicenseWithCnt = {
    indx : number,
    license : ListAllLicensesMsg.UserLicense
}

// max_range is inclusive;
// page_num starts at 0 for first page
// key is a dummy parameter used to force re-render this component by its parent
export function AllLicensesTable(props : { page_num:number|null,
    setPageNum : (n:number)=>void,
    current_user_admin_role : AdminRole,
    split_pages:boolean,
    licenseSorter?:LicenseSorter,
    rowFilter? : RowFilter,
    //key:string,
    allUserLicenses :  ListAllLicensesMsg.UserLicense[],
    filteredUserLicenses : ListAllLicensesMsg.UserLicense[]}) : React.ReactElement {

	/*
	console.log(TAG+'props.allValidUserLicenses.length='+
	    props.allValidUserLicenses.length);
	*/
	
	if(props.split_pages && props.page_num == null) {
	    console.error('props.page_num == null');
	    return (<></>);
	}

	const [roleIsEdittable,setRoleIsEdittable] = React.useState<boolean>(false);
	
	const [minRange, setMinRange ] = React.useState<null|number>(null)
	const [maxRange, setMaxRange ] = React.useState<null|number>(null)

	const [ licensesInRange, setLicensesInRange ] =
	    React.useState< LicenseWithCnt[]|null>(null);

	const set_licenses_in_range = ():void=> {
	    let i = 0;
	    const x = props.filteredUserLicenses.map( (license:ListAllLicensesMsg.UserLicense) => {
		return { indx: i++, license }
	    });
	    console.log(TAG+'set_licenses_in_range: PT1');	    
	    setLicensesInRange(x);
	}

	React.useEffect( ()=> {

	    setRoleIsEdittable([AdminRole.EDITOR, AdminRole.MANAGER ].includes(props.current_user_admin_role) )

	},[props.current_user_admin_role]);

	React.useEffect( ()=> {
	    console.log(TAG+'props.filteredUserLicenses.length='+props.filteredUserLicenses.length);
	    set_licenses_in_range();	    	    
	},[props.filteredUserLicenses, props.rowFilter]);	

	React.useEffect( ()=> {

	    if(props.split_pages && props.page_num!=null) {
		
		const min_range = Math.min(props.filteredUserLicenses.length-1, props.page_num * NUM_PER_PAGE);
		const max_range = Math.min(props.filteredUserLicenses.length, min_range + NUM_PER_PAGE);

		//console.log('props.page_num=' + props.page_num);		
		//console.log('min_range=' + min_range);	    
		//console.log('max_range=' + max_range);		
		
		setMinRange(min_range);
		setMaxRange(max_range);

	    } else {
		setMinRange(0);
		setMaxRange(props.filteredUserLicenses.length,);		
	    }
	    set_licenses_in_range();

	    //console.log('minRange=' + minRange);	    
	    //console.log('maxRange=' + maxRange);

	}, [props.filteredUserLicenses, props.page_num, props.rowFilter]);

	const navigate = useNavigate();
	
	const edit_row = (row :  ListAllLicensesMsg.UserLicense) : void => {
	    //console.log(TAG+'edit_row:' +  JSON.stringify(row,null,2));
	    if(!roleIsEdittable) return;

	    const state = get_edit_license_state({ license : row,
		rowFilterName : props.rowFilter===undefined ? null : props.rowFilter.name,
		licenseSorterName : props.licenseSorter===undefined? null : props.licenseSorter.name,
		page_num : props.page_num,
		allUserLicenses : props.allUserLicenses });
	    
	    sendLocationState<EditLicenseState>(ADMIN.EDIT_LICENSE_URL, state, navigate);
	}

	const dateformat = new Intl.DateTimeFormat();
	
    return (
	
	<Box style={{width:'100%'}}>

	{
	    props.page_num != null &&
		(<>
		  <PageSelection current_page_num={props.page_num==null ? 0:props.page_num}
				 setPageNum={props.setPageNum}
				 rowFilter={props.rowFilter}
				 allUserLicenses={props.allUserLicenses}
				 filteredUserLicenses={props.filteredUserLicenses }
				
		 />
		    <Box sx={{mb:1.5}} ></Box>
		</>
		)
	}
	    
	  
   <TableContainer component={Paper} style={{ borderRadius:'5px'  }}
		   sx={{ boxShadow:3}} >

      <Table
	  sx={{ borderTop: '3px solid #E0E0E0',
	   borderBottom: '3px solid #E0E0E0'}} >
	
        <TableHead  style={{backgroundColor:TABLE_HEADER_BG_COLOR}}>
	  
          <TableRow key='start-row'>

            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">
	    </TableCell>

            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">
	      <HelpPopup label={'Emails'} open={true} msg={EMAILS_ABBREVS}/>
	    </TableCell>	
	
            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">License Key
	    </TableCell>
	
            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">User ID
	    </TableCell>
	
            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">SKU
	    </TableCell>	    

            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">
	      <HelpPopup label={'License Type'} open={true} msg={LICENSE_TYPES_ABBREVS}/>
	    </TableCell>


            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">
             Subscription
	    </TableCell>
	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Issued
	     </TableCell>

	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Expires
	     </TableCell>

	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Edit
	     </TableCell>

	
          </TableRow>
        </TableHead>
	
        <TableBody>
	  
        { minRange != null && maxRange != null && licensesInRange &&
	    licensesInRange.slice(minRange,maxRange).map((entry,index) => {

		const indx = entry.indx;
		const row = entry.license;

		const expiry_date = row.expiry_datetime === 'NONE' || row.expiry_datetime === 'None' ?
		    '-' : dateformat.format(new Date(row.expiry_datetime));
		    //'-' : (new Date(row.expiry_datetime)).toLocaleDateString();

		const emails = get_emails_entry(row);

		//const issued = row.issue_datetime === 'NONE' ? '' : extract_date(row.issue_datetime);
		const issued = row.issue_datetime === 'NONE' || row.issue_datetime === 'None' ? '' :
		    dateformat.format(new Date(row.issue_datetime));
		    //(new Date(row.issue_datetime)).toLocaleDateString();

		//console.log('emails=', emails);

		const license_type = get_license_type(row);

		const cnt = licensesInRange.length===1? '': String(indx+1)+'.';

		const is_subscription = row.subscription_status === 'IS_SUBSCRIPTION' ? 'Y':'';
		
	      return (

             <TableRow key={'LC-'+String(index)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

              <TableCell size="small" align="left">{cnt}</TableCell>
              <TableCell size="small" align="left">{emails}</TableCell>	      
              <TableCell size="small" align="left" style={{ maxWidth:'10em', whiteSpace:'normal',
		  wordWrap:'break-word'}}>{row.license_key}</TableCell>

              <TableCell size="small" align="left" style={{whiteSpace : getwrap(row)}}>{row.as_user_id}</TableCell>
              <TableCell size="small" align="left">{row.as_sku}</TableCell>	      	      
	      	      
              <TableCell size="small" align="left">{license_type}</TableCell>

              <TableCell size="small" align="left">{is_subscription}</TableCell>
	      
              <TableCell size="small" align="left">{issued}</TableCell>	      
              <TableCell size="small" align="left">{expiry_date}</TableCell>
	      
              <TableCell size="small" align="left">
		<>
		{ roleIsEdittable &&
		(<IconButton
		    onClick={()=>edit_row(row)}
		>
		  <EditOutlinedIcon/>
		</IconButton>)
		}
		</>
              </TableCell>
		
            </TableRow>)
          })
	}
	  
        </TableBody>
	
        </Table>
	
    </TableContainer>
	
	{ props.page_num != null &&
	<PageSelection current_page_num={props.page_num==null ? 0:props.page_num}
		       setPageNum={props.setPageNum}
		       allUserLicenses={props.allUserLicenses}
					filteredUserLicenses={props.filteredUserLicenses } 
	/>}
	    
	</Box>	
    );

}
