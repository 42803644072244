// File: copyright.tsx
//


import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { TDB_WEBSITE_URL } from '../config/urls';

export function Copyright(props: any) : React.ReactElement {
    
  return (
      <>
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={TDB_WEBSITE_URL}>
        Helps Ministries
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
      </>
  );
}
