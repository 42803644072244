// File: EcommAuthRequireAuth.tsx
//

import React from 'react';
import { useParams,
    //useNavigate,
    NavLink,
    Outlet, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useECommAuthStatus } from './ecomm_auth_provider';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
//import Link from '@mui/material/Link';
import { ECOMM_AUTH } from '../routes/paths';

const TAG='ECommAuthRequireAuth.tsx: ';

const DEBUG=true;

const theme = createTheme();

export const ECommAuthRequireAuth =
    () : React.ReactElement => {

    const params = useParams();	
    const {user, refresh} = useECommAuthStatus();

    const urlParams = React.useRef<string>('');

    React.useEffect( ()=> {
	refresh();
    },[]);
	
    React.useEffect( ()=> {
	if(params.url_params != null && params.url_params.trim() !== '') {
	    urlParams.current = '/' + params.url_params.trim();
	}
    },[params.url_params]);
	
    React.useEffect( ()=> {
	if(user) {
	    if(DEBUG) {
		console.log(TAG+'user != null')
		console.log(TAG+'user.emailVerified='+user.emailVerified);
	    }
	} else {
	    if(DEBUG) {	    
		console.log(TAG+'user == null')
	    }
	}
    }, [user]);

	/*
 
	{ user == null &&
  	    ( <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs">
		<CssBaseline />	
		  <Navigate to={ECOMM_AUTH.LOGIN_URL+urlParams.current} replace />
	      </Container>
	      </ThemeProvider>)
	}	   
	 */

	//const navigate = useNavigate();
    
    return (
	<>

	{ user == null &&
  	    ( <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs"
			 sx={{p:10}}>
		<CssBaseline />
		<Box style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
		  
                    <Typography variant='h5' align='center'>
		   Not logged in.
                    </Typography>

		<NavLink style={{marginTop:'2em'}}
		to={ECOMM_AUTH.LOGIN_URL+urlParams.current}>
		  
                    <Typography variant='h6' align='center'>		  
		    Go to Login Page
                    </Typography>		      
                </NavLink>		
		
		</Box>
	      </Container>
	      </ThemeProvider>)
	}
	  
	{ user != null &&  ! user.emailVerified &&
 	    (
	      <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs">
		<CssBaseline />	
	 	  <Navigate to={ECOMM_AUTH.EMAIL_NOT_VERIFIED_URL} replace />
	        </Container>
	     </ThemeProvider>)
	}
	
	{ user != null && user.emailVerified &&
		(<Outlet />)	  
	}
	</>
    );
    
}

