// File: mobile_act_instruct.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MainLayout } from '../../utils/main_layout';
import { SideBarType } from '../../utils/side_bar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
//import {Link} from 'react-router-dom';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom';
//import Icon from '@mdi/react';
//import Grid from '@mui/material/Grid';
import {
    APPLE_CONNECT_STORE_MOBILE_APP,
    GOOGLE_PLAY_STORE_MOBILE_APP
} from '../../../config/urls';
import { AndroidLogo, AppleIOSLogo } from '../../utils/store_icons';
import { useNavigate } from 'react-router-dom';
import { LOGGER } from '../../../utils_common/logger';
import { OLDPUB_HOME_URL } from '../../../extlinks/portal_comm_types';

const TAG='mobile_act_instruct.tsx: ';
const DEBUG=true;

const FONT='body1';

/*
const LARGE_FONT='h6';
interface HomeCardContent {
    title : string,
    body:string,
    to:string
}
*/

const FONT_COLOR='#008B8B';

function ContentCard(props : {title:string, children : string|React.ReactElement|
                           React.ReactElement[]} ) :  React.ReactElement {
    return (
	<Box style={{height:'100%', width:'80%'}}
	     sx={{mt:0,mb:5}}
	     >
	  
	  <Card elevation={4} sx={{ml:1, mr:1}}
		style={{ height: '100%'}}>
	    
	    <CardActionArea component='div'
			    style={{textDecoration:'none'}}	      
	    >
	      <CardHeader title={props.title}
			  style={{color:FONT_COLOR,
			      display:'flex', flexDirection:'column',
			      alignItems : 'center', justifyContent:'center'}}
			  component='div'/>
		
	      <CardContent sx={{  paddingTop : "0" }}>

		{props.children}
	      </CardContent>
	      
	    </CardActionArea>
	    
	  </Card>
	</Box>
	);
}

function ScrollToTop() :  React.ReactElement {

    const { pathname } = useLocation();

    React.useEffect( ()=>{
	window.scrollTo(0, 0);
    },[pathname]);

    return (<></>);
}

export function MobileActInstruct() : React.ReactElement {

    const count = React.useRef<number>(0);
    {
	const navigate = useNavigate();	
	count.current = count.current+1;
	if(DEBUG) console.log('***count='+count.current);
	if(count.current > 50) navigate(OLDPUB_HOME_URL);
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_MOBILE_ACT_INSTRUCT',TAG);
    }
    
    const ios_url = APPLE_CONNECT_STORE_MOBILE_APP;
    const android_url = GOOGLE_PLAY_STORE_MOBILE_APP;

    return (
      <MainLayout title='Device Activation'
		  dont_refresh_user_info={true}
		  side_bar_type={SideBarType.MOBUPGRADE_CHILD_PAGE}>
    <ScrollToTop/>
	
      <Box style={{display:'flex', flexDirection:'column',
	  alignItems : 'center', justifyContent:'center'}}>
	  
          <ContentCard title="First install the Lite Version of the mobile app from the app store, if you haven't done so already.">
	    <Box style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>	    
	      
	      <Box style={{display:'flex', flexDirection:'column',
		  marginTop:'0.0em',
		  alignItems : 'center'}}>

		<Box style={{display:'flex', flexDirection:'row'}}>
		  <AppleIOSLogo/>
		<Link  component='button'
		       variant={FONT}
		       style={{ textDecoration:'none'}}
		       onClick={()=> {
			   window.open(ios_url);
		       }}>
       IOS  Apple Store 
		</Link>
		</Box>
		
		<Box style={{marginTop:'0.5em', display:'flex', flexDirection:'row'}}>
		  <AndroidLogo/>
		<Link  component='button'
		       variant={FONT}	  
		       style={{ textDecoration:'none'}}	  
		       onClick={()=> {
			   window.open(android_url);
		       }}>
         Android Google Play Store 
		</Link>
		</Box>
		
	      </Box>
	      
	    </Box>
	  </ContentCard>

	  
          <ContentCard title="Press the upgrade page icon on the app">
	    <Box style={{marginBottom:'1em',display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>	    
              <img src={require('../../utils/app-snapshot.png')} alt=""/>

	    </Box>

	  </ContentCard>
	
 	  
          <ContentCard title="Press the activate button on the upgrade page.">
	    <Box 	
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>
              <img   src={require('../../utils/app-upgrade-blurred.png')} alt=""
	        style={{margin:'-3em',
		    backgroundSize:'contain', transform:'scale(0.64)'}}/>	      

	    </Box>


	  </ContentCard>

 	  
          <ContentCard title="Input email and activation code">
	    <Box 	
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>
              <img   src={require('../../utils/app-activation-page.png')} alt=""
	        style={{margin:'0em', 
		    backgroundSize:'contain', transform:'scale(0.9)'}}/>
	      
	    </Box>
	    
            <Box>

              <Typography align="center" >	      
               Note: email must be same as the one used for login to this website.  
             </Typography>
              <Typography align="center" >	      
               In some cases, the email is not required and will not be requested.
             </Typography>	      	     
            </Box>
	    
	  </ContentCard>	  	  	  
	
      </Box>	            
  	   </MainLayout>

    )
}
