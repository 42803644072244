// File: go_back_to_licenses_button.tsx
//

import * as React from 'react';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { BackLicensesTableState } from './licenses_loc_states';
import { ADMIN } from '../../../routes/paths';
//const TAG='go_back_to_licenses_button.tsx: ';
//const DEBUG=true;

export function GoBackToLicensesButton(props:{backState : BackLicensesTableState}) : React.ReactElement {

    return (
	(<Box  sx={{mb:2}}>
		  <center><Link to={ADMIN.SEND_MOBUPGRADE_INVITES_URL}
		      state={{...props.backState}}>
		  <Button>Go Back to All Licenses</Button>
		  </Link></center>
		</Box>)
    );
}
	      
