// File: ecomm_auth_server_comm.ts
//

import {
    MsgStatus,
    ResultType,
    RequestType,
    ResponseType,
    ReqEmailMobileActCode,
    GetUpgradePopupSession,
    CheckLicenseEligibleForMobileUpgrade,
    PopupCreateMobileUpgradeLicense,
    ReqMobUpgradeFSProdURL,
    ECommAuth
} from '../../extlinks/portal_comm_types';

import { getFunctions,  httpsCallableFromURL } from "firebase/functions";
import { get_firebase_functions_portalcomm } from '../../config/firebase_config';

const TIMEOUT=30000;

const TAG='ecomm_auth_server_comm.ts: ';
const DEBUG=true;

const PORTAL_FUN_URL=get_firebase_functions_portalcomm();


// for calling onCallable firebase functions
async function query_server<TIn,TOut extends ResultType>(input : TIn,
                                                         cmd:string,
                                                         options? :{cancelExec? : ()=>boolean }) : Promise<TOut|null> {

    const functions = getFunctions();

    const portalFun = httpsCallableFromURL<RequestType<TIn>,ResponseType<TOut>>(
        functions,
        PORTAL_FUN_URL,
        { timeout:TIMEOUT}        
//        {limitedUseAppCheckTokens:true }   // is in beta
    );

    const msg_input:RequestType<TIn> = { cmd:cmd,  input:input }
    
    return new Promise<TOut|null>( (resolve,reject) => {

        if(options!=null && options.cancelExec) {
            setInterval( ()=> {
                if(options!=null && options.cancelExec && options.cancelExec()) {
                    resolve(null);
                }
            }, 5000);
        }
        
        portalFun(msg_input).then( (x) => {
            resolve(x.data.result);
        }).catch( (error) => {
            console.error(TAG+'query_server: error=' + String(error));
            console.error(TAG+'query_server: problems with appcheck for localhost?');                        
            //console.trace();
            //throw new Error(error);
            reject(error);
        });
    });
}

export class ECommAuthServerComm {
    private ECommAuthServerComm_:undefined;
    
    async handle_req_email_mobile_act_code(input:ReqEmailMobileActCode.InType) : Promise<ReqEmailMobileActCode.OutType> {
        
        const out = await query_server<ReqEmailMobileActCode.InType,
                                       ReqEmailMobileActCode.OutType>(input,
                                                                  ReqEmailMobileActCode.CMD);

        if(out == null) {
            return ReqEmailMobileActCode.ERROR_OUT;
        }

        return out;                              
    }


    async handle_desktop_license_eligible_for_mobile_upgrade(input:CheckLicenseEligibleForMobileUpgrade.InType) : Promise<CheckLicenseEligibleForMobileUpgrade.OutType> {

        if(DEBUG) console.log(TAG+'entering handle_desktop_eligible_for_mobile_upgrade');

        const out = await query_server<CheckLicenseEligibleForMobileUpgrade.InType,
                                        CheckLicenseEligibleForMobileUpgrade.OutType>(input,
                                                                  CheckLicenseEligibleForMobileUpgrade.CMD);

        if(DEBUG) console.log(TAG+'handle_desktop_license_eligible_for_mobile_upgrade: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                is_valid :false,
                error_message:'server error'
            }
        }

        return out;
    }

    async handle_get_encrypted_session(input : GetUpgradePopupSession.InType) : Promise<GetUpgradePopupSession.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_get_encrypted_session');

        const out = await query_server<GetUpgradePopupSession.InType,
                                        GetUpgradePopupSession.OutType>(input,
                                                                  GetUpgradePopupSession.CMD);

        if(DEBUG) console.log(TAG+'handle_get_encrypted_session: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                encrypted_session : {
                    secured_data : '',
                    secured_key : ''
                }
            }
        }


        return out;
    }


    async handle_popup_create_mobile_upgrade(input:PopupCreateMobileUpgradeLicense.InType) : Promise<PopupCreateMobileUpgradeLicense.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_popup_create_mobile_upgrade');
        const out = await query_server<PopupCreateMobileUpgradeLicense.InType,
                                        PopupCreateMobileUpgradeLicense.OutType>(input,
                                                             PopupCreateMobileUpgradeLicense.CMD);

        if(DEBUG) console.log(TAG+'handle_popup_create_mobile_upgrade: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                is_valid:false,
                error_message:'server error',
                cancel_order_status:PopupCreateMobileUpgradeLicense.CancelOrderStatus.ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED
            }
        }

        return out;
    }


    async handle_get_mob_upgrade_prod_url(input:ReqMobUpgradeFSProdURL.InType) : Promise<ReqMobUpgradeFSProdURL.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_popup_create_mobile_upgrade');
        const out = await query_server<ReqMobUpgradeFSProdURL.InType,
                                        ReqMobUpgradeFSProdURL.OutType>(input,
                                                             ReqMobUpgradeFSProdURL.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                fs_prod_url: ''
            }
        }

        return out;
    }
/*
    async handle_req_landing_page_type(input:ECommAuth.ReqLandingPageType.InType) : Promise<ECommAuth.ReqLandingPageType.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_landing_page_type');
        const out = await query_server<ECommAuth.ReqLandingPageType.InType,
                                                             ECommAuth.ReqLandingPageType.OutType>(input,
                                                             ECommAuth.ReqLandingPageType.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return ECommAuth.ReqLandingPageType.ERROR_OUT;
        }

        return out;
    }
*/
    async handle_req_license_keys(input:ECommAuth.ReqLicenseKeys.InType) : Promise<ECommAuth.ReqLicenseKeys.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_license_keys');
        const out = await query_server<ECommAuth.ReqLicenseKeys.InType,
                                                             ECommAuth.ReqLicenseKeys.OutType>(input,
                                                             ECommAuth.ReqLicenseKeys.CMD);

        if(DEBUG) console.log(TAG+'handle_req_license_keys: out=',out);

        if(out == null) {
            return ECommAuth.ReqLicenseKeys.ERROR_OUT;
        }

        return out;
    }

    async handle_req_user_licenses_info(input:ECommAuth.ECommGetUserLicensesInfo.InType) : Promise<ECommAuth.ECommGetUserLicensesInfo.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_user_licenses_info');
        const out = await query_server<ECommAuth.ECommGetUserLicensesInfo.InType,
                                                             ECommAuth.ECommGetUserLicensesInfo.OutType>(input,
                                                             ECommAuth.ECommGetUserLicensesInfo.CMD);

        if(DEBUG) console.log(TAG+'handle_req_user_licenses_info: out=',out);

        if(out == null) {
            return ECommAuth.ECommGetUserLicensesInfo.ERROR_OUT;
        }

        return out;
    }

    async handle_check_ecomm_url_params(input:ECommAuth.CheckUrlParams.InType) : Promise<ECommAuth.CheckUrlParams.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_check_ecomm_url_params');
        const out = await query_server<ECommAuth.CheckUrlParams.InType,
                                                             ECommAuth.CheckUrlParams.OutType>(input,
                                                             ECommAuth.CheckUrlParams.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return ECommAuth.CheckUrlParams.ERROR_OUT;
        }

        return out;
    }        

    async handle_ecomm_auth_get_signin_token_from_url_token(input : ECommAuth.GetSigninTokenFromUrlToken.InType) : Promise<ECommAuth.GetSigninTokenFromUrlToken.OutType> {
        try {
            const out = await query_server<ECommAuth.GetSigninTokenFromUrlToken.InType,
                               ECommAuth.GetSigninTokenFromUrlToken.OutType >(input, ECommAuth.GetSigninTokenFromUrlToken.CMD);

            if(out == null) {
                console.error(TAG+'ECommAuth.GetSigninTokenFromUrlToken: out==null');
                return ECommAuth.GetSigninTokenFromUrlToken.ERROR_OUT;
            }
            return out;

        } catch(error) {
            console.error(TAG+'PWLessGetSigninTokenFromUrlToken: error='+error);        
            return ECommAuth.GetSigninTokenFromUrlToken.ERROR_OUT;
        }
    }


    async handle_ecomm_auth_request_email_verify_link(input : ECommAuth.RequestEmailLoginLink.InType) : Promise<ECommAuth.RequestEmailLoginLink.OutType> {
        try {
            const out = await query_server<ECommAuth.RequestEmailLoginLink.InType,
                               ECommAuth.RequestEmailLoginLink.OutType >(input, ECommAuth.RequestEmailLoginLink.CMD);

            if(out == null) {
                console.error(TAG+'ECommAuth.RequestEmailLoginLink: out==null');
                return ECommAuth.RequestEmailLoginLink.ERROR_OUT;
            }
            return out;

        } catch(error) {
            console.error(TAG+'PWLessRequestEmailLoginLink: error='+error);        
            return ECommAuth.RequestEmailLoginLink.ERROR_OUT;
        }
    }

    async handle_req_mobupgrade_parent_license_key(input:ECommAuth.ReqMobUpgradeParentLicenseKey.InType) : Promise<ECommAuth.ReqMobUpgradeParentLicenseKey.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_mobupgrade_parent_license_key');
        const out = await query_server<ECommAuth.ReqMobUpgradeParentLicenseKey.InType,
                                                             ECommAuth.ReqMobUpgradeParentLicenseKey.OutType>(input,
                                                             ECommAuth.ReqMobUpgradeParentLicenseKey.CMD);

        if(DEBUG) console.log(TAG+'entering handle_req_mobupgrade_parent_license_key: out=',out);

        if(out == null) {
            return ECommAuth.ReqMobUpgradeParentLicenseKey.ERROR_OUT;
        }

        return out;
    }

}
 
export function getECommAuthServerComm() : ECommAuthServerComm {
    return new ECommAuthServerComm();     
}
