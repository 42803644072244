// File: page_selection.tsx
//

import * as React from 'react';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import { GetMobUpgradeableLicenses } from '../../../extlinks/portal_comm_types';
import { RowFilter } from './select_filter_rows';

const TAG='page_selection.tsx: ';

type LicenseInfo = GetMobUpgradeableLicenses.ParentLicenseInfo;

// dont put drop down more than because changing one wont set the
// selection on the other
export function PageSelection(props: { current_page_num:number,
    setPageNum : (n:number)=>void,
    numPerPage:number,
    rowFilter:RowFilter,
    allLicenseInfos : LicenseInfo[],
    filteredLicenseInfos : LicenseInfo[]}) :  React.ReactElement {

	//const [ maxPages, setMaxPages ] = React.useState<number>(1);
	const [ pageNums, setPageNums ] = React.useState<number[]>([1]);
	
	React.useEffect( ()=> {
	    const num_per_page = Math.max(1, props.numPerPage);
	    const num_all_entries = props.filteredLicenseInfos.length;
	    const maxPages = Math.ceil( num_all_entries / num_per_page);

	    console.log(TAG+'num_all_entries='+num_all_entries);
	    console.log(TAG+'num_per_page='+num_per_page);
	    console.log(TAG+'maxPages='+maxPages);
	    
	    const nums : number[] = [];
	    for(let i=0;i<maxPages;i++) nums.push(i);
	    setPageNums(nums);
	    
	}, [props.numPerPage, props.rowFilter, props.filteredLicenseInfos]);

	let cnt = 0;

	
    return (<>
	
	<Box component='div'
	     sx={{mt:2}}
	     style={{display:'flex', width:'100%',
		 flexDirection:'column',
		 alignItems : 'center'}}>	  
  
	<Box component='div'
	     sx={{mt:2}}
	     style={{display:'flex', width:'100%',
		 flexDirection:'row', 
		 alignItems : 'center', justifyContent:'center'}}>

	<Box component='div'
	     style={{display:'flex', flexWrap:'wrap', maxWidth:'80%',
		 flexDirection:'row', 
		 alignItems : 'center', justifyContent:'center'}}>

        { pageNums.map( (n:number)=> {
		//console.log('n='+n);
		return (
		<Box component="div" key={'page-selection'+cnt++}
		     sx={{pl:1,pr:1,
			 backgroundColor: (n===props.current_page_num ? "lightblue ":"white")}}>

		<Link to='' onClick={()=>{props.setPageNum(n)}}> {String(n+1)}</Link>		  
		</Box>)
	    }
	)
	}

	</Box>
	</Box>
	</Box>		
    </>);
}

