// File: desktop_install.tsx
//

import * as React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Copyright } from '../../utils_common/copyright';
//import { ECOMM_AUTH } from '../../routes/paths';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

const TAG='desktop_install.tsx: ';
const DEBUG=true;

const TDB_TRIAL_URL='https://discoverybible.com/free-trial/'

//const FONT='body1';
const FONT_COLOR='#008B8B';

function ContentCard(props : {title:string, children : string|React.ReactElement|
                           React.ReactElement[]} ) :  React.ReactElement {
			       
    return (
	<Box style={{maxWidth:'50em', height:'100%',  backgroundColor:'white', borderRadius:'6pt',
	       display:'flex', flexDirection:'column',
	      alignItems : 'center', justifyContent:'flex-start'
	}}
	     sx={{mt:0,mb:5, boxShadow:3, p:3,pt:5,pb:5}}
	     >

	  <Box style={{maxWidth:'50em',
	      display:'flex', flexDirection:'column',
	      alignItems : 'center', justifyContent:'flex-start'}}
	  >
	  <Typography align='center'
		      variant='h5'
		      sx={{mb:2,pr:6,pl:6}}
		      style={{ fontWeight:'bold',color:FONT_COLOR}}>
            {props.title}	    
	  </Typography>
	  </Box>
	  
	  <Box style={{width:'100%', height:'100%'}}>
		{props.children}
          </Box>	    
	</Box>
	);
}

function ScrollToTop() :  React.ReactElement {

    const { pathname } = useLocation();

    React.useEffect( ()=>{
	window.scrollTo(0, 0);
    },[pathname]);

    return (<></>);
}


function  DesktopAppInstallInstructionsImpl() : React.ReactElement {

    return (
	
      <Box  style={{width:'100%',
	  display:'flex', flexDirection:'column',
	  alignItems : 'center', justifyContent:'center'	  
      }}>
      <ScrollToTop/>
    
      <Box style={{maxWidth:'70em',display:'flex', flexDirection:'column',
	  alignItems : 'center', justifyContent:'center'}}>

	<Box sx={{mb:4}}>
	  <Typography variant='h4' textAlign='center' >
	    Desktop App Installation Instructions
	  </Typography>	    
	</Box>	  
	  
          <ContentCard title="If you haven't done so already, install the Trial Version of the desktop app from the following link.">
	    <Box style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>	    
	      
	      <Box style={{display:'flex', flexDirection:'column',
		  marginTop:'0.0em',
		  alignItems : 'center'}}>

		<Box style={{display:'flex', flexDirection:'row'}}>
		<Link 	variant='h5' 
	                 href={TDB_TRIAL_URL} >
                Discovery Bible Trial Version
		</Link>
		</Box>
				
	      </Box>
	      
	    </Box>
	  </ContentCard>
	  
          <ContentCard  title="After installing the Trial Version, press 'Help' on the top menu bar.">
	    <Box style={{padding:'0',margin:'0',display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
	      
              <img style={{margin:'0',objectFit:'cover', width:'90%' }}
	           src={require('./desktop-images/desktop-app1-mod.png')} alt=""/>


            </Box>
	  </ContentCard>
 	  
          <ContentCard title="Select 'Enter license key' on the dropdown menu that appears.">
	    <Box 	
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>
              <img   src={require('./desktop-images/desktop-app2-mod.png')} alt=""
	        style={{ objectFit:'contain', width:'90%'}}/>	      

	    </Box>

	  </ContentCard>

          <ContentCard title="Input the user identification and serial key given in your email message and press 'OK'.">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>

	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
              <img   src={require('./desktop-images/desktop-app3-mod.png')} alt=""
	        style={{margin:'0em',  objectFit:'contain',  width:'90%'}}/>
	      
	    </Box>

	    
	    </Box>
	  </ContentCard>


          <ContentCard title="Press 'OK' if the app requests to exit.  In some cases the app may also ask to reboot the computer.">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>

	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
              <img   src={require('./desktop-images/desktop-app4.png')} alt=""
	        style={{margin:'0em',  objectFit:'contain', width:'90%'}}/>
	      
	    </Box>


	    </Box>
	  </ContentCard>	  	  	  	  
	
          <ContentCard title="Answer the User License Agreement.">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>


	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
              <img   src={require('./desktop-images/desktop-app5.png')} alt=""
	        style={{margin:'0em' , objectFit:'contain', width:'90%'}}/>
	      
	    </Box>

	    
	    </Box>
	  </ContentCard>	  	  	  	  
		
          <ContentCard title="Go back to the 'Help' menu and select 'About the Discovery Bible'.">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>


	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'flex-start'}}>
              <img   src={require('./desktop-images/desktop-app6-mod.png')} alt=""
	        style={{margin:'0em',  objectFit:'contain', width:'90%'}}/>
	      
	    </Box>

	    <Box sx={{mt:5}} style={{maxWidth:'40em'}}>	      
	      <Typography  variant='h5' align='center' style={{fontWeight:'bold', color:FONT_COLOR}}>
		If you see your name on the window that pops up, you are all set to use the full features of the Discovery Bible.
	      </Typography>		      
	    </Box>
	    
	    </Box>
	  </ContentCard>	  	  	  	  
	
      </Box>

  	   </Box>

    )
}

const theme = createTheme();

export function ECommAuthDesktopInstallInstructions(props:{}) : React.ReactElement {

    return(
 <ThemeProvider theme={theme}>
        <Container component="main" maxWidth={false}
		   sx={{mt:0, pt:5, pb:2}}
		   style={{width:'100vw', backgroundColor : 'rgb(240, 240, 240)'}}>
      <CssBaseline/>
          <DesktopAppInstallInstructionsImpl/>

	  <Copyright sx={{ mt: 5, mb: 4 }} />

      </Container>
 </ThemeProvider>
    )
}

    
