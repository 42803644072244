// File: config.ts
//

// if false, directs to FS mobile upgrade product webpage
export const MOBILE_UPGRADE_USES_POPUP=true;

export const ECOMM_TOKEN_TESTING=true;

export const ECOMM_AUTH_TESTING=true;

export const USE_ROUTES_OLDPUB=true;

export const USE_ROUTES_ECOMM_AUTH=true;

export const USE_ROUTES_ECOMM_TOKEN=false;

// NOTE: index.html also has url helpsministries.test.onfastspring.com that has to  be
// changed or added if following is changed
export const FS_POPUP_MOBUPGRADE_PROD_PATH="helpsministries.test.onfastspring.com/popup-mobile-upgrade-test";
