// File: email_login_page.tsx
//

import * as React from 'react';
import { delete_ecomm_session_token,
//    get_ecomm_session_token,
    store_ecomm_session_token } from './utils/ecomm_session_tokens';
import { getECommTokenServerComm } from './utils/ecommtoken_server_comm';
import { ECOMM_TOKEN_DISPLAY_LICENSE_KEYS_LANDING_URL, ECOMM_TOKEN_NOT_FOUND_URL, MsgStatus } from '../extlinks/portal_comm_types';
import { useParams, useNavigate} from 'react-router-dom';
//import { check_url_params } from './utils/check_url_params';
//import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RequestEmail } from '../utils_common/request_email';
import { Copyright } from '../utils_common/copyright';
import { Progress } from '../utils_common/progress';
import { MsgAlertDialog } from '../utils_common/alert_dialog';
import { assertUnreachable} from '../utils_common/assert_unreachable';
import { ECOMM_TOKEN_TESTING } from '../config/config';

const TAG='email_login_page.tsx: ';
const DEBUG=true;

const theme = createTheme();

export function ECommTokenEmailLogin(props:{}) : React.ReactElement {

    const [ msg, setMsg ] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    
    const { url_params } = useParams();
    
    const navigate = useNavigate();
    
    const server = getECommTokenServerComm();
    
    React.useEffect(()=> {
	if(url_params === undefined) {
	    console.error(TAG+'url_params==undefined');
	    navigate(ECOMM_TOKEN_NOT_FOUND_URL);	    
	    return;
	}

	setIsLoading(true);
	setMsg('');
	server.handle_check_ecomm_url_params({url_params}).then(
	    (result)=> {
		setIsLoading(false);		
		if(result.status !== MsgStatus.OK) {
		    setMsg('Internal error');
		    return;
		} else if( ! result.ok) {
		    console.error(TAG+'handle_check_ecomm_url_params: returned result.ok == false');
		    if(ECOMM_TOKEN_TESTING) {
			setMsg('server said url_params invalid');
		    } else {
			navigate(ECOMM_TOKEN_NOT_FOUND_URL);
		    }
		    return;
		}
	    }
	).catch((error)=>{
	    setIsLoading(false);			    
	    console.error(TAG+'handle_check_ecomm_url_params: returned error='+error);
	    setMsg('Internal error');	    
	});
	
    },[]);
        
    const submit = (user_email:string):void=> {

	if(url_params === undefined) {
	    console.error(TAG+'url_params==undefined');
	    if(ECOMM_TOKEN_TESTING) {	    
		setMsg('PT1: url_params===undefined');
	    } else{
		navigate(ECOMM_TOKEN_NOT_FOUND_URL);
	    }
	    return;
	}
	
	setIsLoading(true);
	setMsg('');
	server.handle_req_session_token({url_params, user_email}).then(
	    (result)=> {
		setIsLoading(false);

		if(result.status === MsgStatus.ERROR) {

		    setMsg('Internal error.')
		    return;

	    
		} else if(result.req_status==='OK') {

		    if(result.session_token==null) {
			setMsg('Internal error.')
			return;
		    }

		    store_ecomm_session_token({session_token : result.session_token});
		    
		    if(ECOMM_TOKEN_TESTING) {
			setMsg('Email OK.')
			//!!!!!!!
			//navigate(ECOMM_DISPLAY_LICENSE_KEYS_LANDING_URL+'/' + url_params)			
		    } else {
			//!!!!!!!
			navigate(ECOMM_TOKEN_DISPLAY_LICENSE_KEYS_LANDING_URL+'/' + url_params)
		    }
		    
		} else {
		    delete_ecomm_session_token();
		    console.error(TAG+'submit: result.status='+result.status);
		    switch(result.req_status) {
			case 'INVALID_EMAIL':
			    setMsg('Email invalid.')
			    return;
			case 'URL_PARAMS_EXPIRED':
			    navigate(ECOMM_TOKEN_NOT_FOUND_URL);
			    return			    
			case 'INVALID_URL_PARAMS':			    			    
			    navigate(ECOMM_TOKEN_NOT_FOUND_URL);
			    return
			case 'INTERNAL_ERROR':
			    setMsg('Internal error.')
			    return;
		    }
		    
		    // eslint-disable-next-line no-unreachable
		    assertUnreachable(result.req_status);
		}
	    }
	).catch(
	    (error)=> {
		setIsLoading(false);		
		console.error(TAG+'submit: error=' + error);
		setMsg('Server error. Try again later.')		
	});	
    }
    
    return (
 <ThemeProvider theme={theme}>
    <Container component="main" maxWidth='md' >

      <CssBaseline/>

      <RequestEmail testing={false}
		    caption='Enter email'
		    submit={submit}/>

	    
	  <Copyright sx={{ mt: 5, mb: 4 }} />
	  
	  <MsgAlertDialog msg={msg} setMsg={setMsg} />
	  
	{ <Progress isLoading={isLoading}/> }
	  
   </Container>
</ThemeProvider>);
 
}


