// File: session_timeout.tsx
//

import React from 'react';
import ActivityDetector from 'react-activity-detector';
import { AlertDialog} from './alert_dialog';

const TAG='session_timeout.tsx: ';
const DEBUG =false;

const TIMEOUT = 1000*30*60;

interface MsgDialogProps {
    msg:string,
    handle_continue: ()=>void
}

function MsgDialog(props: MsgDialogProps) : React.ReactElement {
    
    const get_msg_dialog_is_open = () : boolean => {
	return props.msg !== '';
    }

    const msg_dialog_props = {
	open : get_msg_dialog_is_open,
	handle_close : ()=>{},
	handle_choice1 :props.handle_continue,
	handle_choice2 :()=>{},
	
	title : props.msg,
	text : '',
	choice1 : 'Continue Session',
	choice2 : ''
    }
    
    return (
	<AlertDialog {...msg_dialog_props} />
    );
}

export function SessionTimeout(props:{
    onTimeoutMsg : (secs : number)=>string,
    timeout?: number,
    onTimeout:()=>void}) : React.ReactElement {
    
    const onTimeout = ():void => {
	props.onTimeout();
    }

    const [ msg , setMsg ] = React.useState<string>("");
    
    const timeout  = React.useRef<null | ReturnType<typeof setTimeout>>(null);

    const idle = React.useRef<boolean>(false);

    const interval  = React.useRef<null | ReturnType<typeof setInterval>>(null);

     //const [ detectorEnabled, setDetectorEnabled ] = React.useState<boolean>(true);

    const check_timeout = ():void=> {
	if(DEBUG) {
	    console.log(TAG+'check_timeout: idle='+idle.current);
	}
	if(idle.current) {
	    if(DEBUG) console.log(TAG+"***TIMEOUT***");
	    if(interval.current == null) {
		let cnt = 15; // secs countdown to logout		
		setMsg(props.onTimeoutMsg(cnt));
		interval.current = setInterval(()=>{
		    cnt -= 1;
		    console.log(TAG+'cnt='+cnt)
		    if( ! idle.current) {
//  eslint-disable-next-line @typescript-eslint/strict-boolean-expressions			
			if(interval && interval.current) {
			    clearInterval(interval.current);
			    interval.current = null;
			}
			setMsg('');
			return;
		    }
		    if(cnt <= 0) {
//  eslint-disable-next-line @typescript-eslint/strict-boolean-expressions			
			if(interval && interval.current) {
			    clearInterval(interval.current);
			    interval.current = null;
			}
			setMsg('');			
			if(DEBUG) console.log(TAG+'***TIMED OUT***');			
			onTimeout();
		    } else {
			setMsg(props.onTimeoutMsg(cnt));
		    }
		}, 1000);
	    }
	}
	if(timeout.current) {
	    timeout.current = null;	
	}
    };
    
    const onIdle = ():void => {
	if(!timeout.current) timeout.current = setTimeout(check_timeout,
	    props.timeout? props.timeout : TIMEOUT);
	idle.current = true;
	if(DEBUG) console.log(TAG+"The user is idle...: idle="+idle.current);
    }

    const onActive = () :void => {
	if(DEBUG) console.log(TAG+"The user is active!");
	if(timeout.current) {
	    clearTimeout(timeout.current);
	    timeout.current = null;
	}
	idle.current = false;
	setMsg('');	
    }
    
    const handle_continue = ():void=>{
	// note: clicking on the dialog button will automatically call onActive()
	setMsg('');
    };
    
    return (<>
      <MsgDialog msg={msg} handle_continue={handle_continue} />
      <ActivityDetector
	  activityEvents={['click', 'mousemove', 'keydown',
	      'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove',
	  ]}
	  enabled={true} timeout={5*1000} onIdle={onIdle} onActive={onActive} name="default"/>
	</>)
}

