// file: sidebar_items.tsx
//

import * as React from 'react';
import Icon from '@mdi/react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material/Divider';
import {mdiLockOutline, mdiTableKey,
    mdiHomeOutline,
    mdiShieldAccountOutline,    
    mdiDevices,
    mdiLicense,
    mdiEmailOutline,    
}  from '@mdi/js/mdi'
import LogoutIcon from '@mui/icons-material/Logout';
import { NavLink, useLocation } from 'react-router-dom';
import { LogoutDialog } from '../../utils_common/logout_dialog';
import { AdminSideBarProps } from './side_bar_props';
import {  AdminRole, OLDPUB_HOME_URL } from '../../extlinks/portal_comm_types';
import { ADMIN } from '../../routes/paths';

const TAG='sidebar_items.tsx: ';
const DEBUG=false;

export const FONT_COLOR='#008B8B';

// if the link goes to current page path, this
// link deactivates mouse pointer event and highlights the
// enclosed button; this prevents freezing of page in this
// circumstance
function SideBarLink(props : {key:string, to:string,
    children:React.ReactNode|React.ReactElement[] }) : React.ReactElement {
	
	const location = useLocation();

	let style :object =
		{ textDecoration:'none', pointerEvents:undefined,
		    color:FONT_COLOR};

	let path = props.to;
	let selected = false;
	if(location.pathname === props.to) {
	    style = {textDecoration:'none', pointerEvents :'none'};
	    path = '';
	    selected = true;
	}

	return (
	    <NavLink to={path} style={style}> 	    
	    <ListItemButton selected={selected}
			    style={{color:FONT_COLOR}}>
	    {props.children}
            </ListItemButton>
            </NavLink>
	);    
}

type Item = {
    icon_path : string,
    title:string,
    to:string,
    rotate_deg:number,
    bg_color?: string,
    icon_size?:number,
}

const TO_ADMIN_HOME :Item = {
    title : 'Admin Home',
        icon_path : mdiShieldAccountOutline,
    to : ADMIN.HOME_URL,
    rotate_deg:0,
    icon_size:1.2
}

const DISPLAY_ALL_LICENSES :Item  = {
    title : 'Display Licenses',
    icon_path:mdiLicense,
    to : ADMIN.LIST_ALL_LICENSES_URL,    
    rotate_deg:0        
}
	
const EDIT_ADMIN_LIST :Item = {
    title:'Edit Admin List',
    icon_path: mdiTableKey,
    to: ADMIN.EDIT_ADMIN_LIST_URL,
    rotate_deg:0    
}

const FREE_MOBILE_LICENSES : Item = {
    title:'Free Mobile Licenses',
    icon_path: mdiDevices,
    to: ADMIN.FREE_MOBILE_LICENSES_URL,
    rotate_deg:0        
}

const SEND_MOBUPGRADE_INVITES : Item = {
    title:'Send Mobile Upgrade Invites',
    icon_path: mdiEmailOutline,
    to: ADMIN.SEND_MOBUPGRADE_INVITES_URL,
    rotate_deg:0        
}

const USER_HOME :Item = {
    title:'Non-Admin Home',
    icon_path:  mdiHomeOutline,
    to: OLDPUB_HOME_URL,
    rotate_deg:0    
}

function get_main_items(side_bar_props  :  AdminSideBarProps|null) : Item[] {
    if(side_bar_props==null) return [];

    if(DEBUG) console.log(TAG+'entering get_main_items');
    
    let out :  Item[] = [];

    out.push(TO_ADMIN_HOME);

    out.push(DISPLAY_ALL_LICENSES);
    
    if(side_bar_props.current_user_admin_role === AdminRole.MANAGER) {
	out.push(EDIT_ADMIN_LIST);
	
    }

    out.push(FREE_MOBILE_LICENSES);
    
    if(side_bar_props.current_user_admin_role === AdminRole.MANAGER ||
	side_bar_props.current_user_admin_role === AdminRole.EDITOR) {
	out.push(SEND_MOBUPGRADE_INVITES); 
    }
    
    out.push(USER_HOME);
    
    return out;
}

export function
AdminSideBarMainItems(props : AdminSideBarProps|null) : React.ReactElement {
    let cnt = 0;
    return (
  <React.Fragment>
    <>

    <Box>
    {  get_main_items(props).map( (x) => {
	   const icon_size = x.icon_size ? x.icon_size : 1;
	   const bg_color = x.bg_color ? x.bg_color : 'transparent';	   
	    return (
		<Box sx={{backgroundColor:bg_color}} key={'AdminSideBarMainItems'+cnt++}>		
		  <SideBarLink key={x.title.replace(' ','-')} to={x.to}>
		    <ListItemIcon>
		      {x.icon_path.trim() !== '' &&
		      (<Icon path={x.icon_path}
			    style={{transform: `rotate(${x.rotate_deg}deg)`}}
			    size={icon_size}/>)
		      }
		    </ListItemIcon>
		    <ListItemText primary={x.title} />      
		  </SideBarLink>
		</Box>
	    )      
	    
	})
    }
    </Box>

    </>
  </React.Fragment>
);
    
}


export function
SideBarSecondaryItems(side_bar_props : AdminSideBarProps|null) : React.ReactElement {

    if(side_bar_props ==null) return (<></>)
    
    const [ openLogout, setOpenLogout ] = React.useState(false);
    
    return (
  <React.Fragment>

    <SideBarLink key={'ChangeEmail'} to={ADMIN.CHANGE_EMAIL_ENDING_URL}>
      <ListItemIcon>
	<Icon path={mdiEmailOutline} size={1}/>	
      </ListItemIcon>
      <ListItemText primary="Change Email" />      
    </SideBarLink>      

    <SideBarLink  key={"ChangePassword"} to={ADMIN.CHANGE_PASSWORD_ENDING_URL}>
      <ListItemIcon>
	<Icon path={mdiLockOutline} size={1}/>	
      </ListItemIcon>
      <ListItemText primary="Change Password" />
    </SideBarLink>      

    <Box style={{height:'2em'}}/>

      <ListItemButton  onClick={()=>setOpenLogout(true)}>            
      <ListItemIcon>
	<LogoutIcon/>
      </ListItemIcon>
      <ListItemText primary="Logout" />
       </ListItemButton>      

      <LogoutDialog
	  signInUrl={side_bar_props.signInUrl}
	  open={()=>openLogout}
	  close={()=>setOpenLogout(false)} />
      
      </React.Fragment>);
}
