// File: pub_main_layout_provider.tsx
//

import React from 'react';
import equal from 'deep-equal';
import { IdTokenResult, getAuth} from 'firebase/auth';
import * as firebase_auth from 'firebase/auth';
import { useAuthStatus } from '../../auth_provider/use_auth';
import { getServerComm } from './server_comm';
import { AdminRole, GetUserLicensesInfo, MsgStatus, OLDPUB_SIGNIN_ENDING_URL, UserPurchaseInfo } from '../../extlinks/portal_comm_types';
import { get_session_token } from '../../utils_common/logger';
import { SessionTimeout } from '../../utils_common/session_timeout';
import { useNavigate } from 'react-router';
import { clear_reg_logger_session_token } from '../../utils_common/reg_logger_session_token';
import {  LOGGER } from '../../utils_common/logger';

const TAG='pub_main_pages_provider.tsx: ';
const DEBUG =false;

export type UserLicensesInfo = GetUserLicensesInfo.UserLicensesInfo;

export interface PubMainPagesContextState {
    user_admin_role : AdminRole,
    purchase_info : UserPurchaseInfo|null,
    user_licenses_info : UserLicensesInfo|null,
    is_loading : boolean,
    refresh_user_licenses_info : ()=>void
}

export const MainPagesContext : React.Context<PubMainPagesContextState>
         = React.createContext<PubMainPagesContextState>({
	     user_admin_role:AdminRole.NONE,
	     purchase_info: null,
	     user_licenses_info: null,
	     is_loading : false,
	     refresh_user_licenses_info : ()=>{}
	 });

export const useMainPagesContext = () : PubMainPagesContextState => {
    return React.useContext<PubMainPagesContextState>(MainPagesContext);
}

export const PubMainPagesProvider = 
    (props : { children : React.ReactElement | React.ReactElement[]}) : React.ReactElement => {

	const [ userAdminRole, setUserAdminRole ] = React.useState<AdminRole>(AdminRole.NONE);
	
	const [ userLicensesInfo, setUserLicensesInfo ] =
	    React.useState<UserLicensesInfo|null>(null);

	const [ isLoading, setIsLoading ] = React.useState<boolean>(false);

	const init_cnt = React.useRef<number>(0);
	
	const ref = React.useRef<UserLicensesInfo|null>(null);

	const { user, refresh:refreshAuthStatus } = useAuthStatus();

	const server = getServerComm();

	const set_user_admin_role = (idTokenResult: IdTokenResult):void => {
	    
	    refreshAuthStatus();
	    
	    if(DEBUG) console.log(TAG+'set_user_admin_role: idTokenResult.claims=', idTokenResult.claims);		
	    let user_admin_role = AdminRole.NONE;
	    if('admin_role' in idTokenResult.claims) {
		const admin_role_str =  idTokenResult.claims['admin_role'];
		const x = AdminRole[ admin_role_str as keyof typeof AdminRole ];
		if(x!== undefined) user_admin_role = x;
	    }
	    
	    if(DEBUG) console.log(TAG+'user_admin_role=' + user_admin_role);
	    
	    setUserAdminRole(user_admin_role);	    	    
	}

	const init_user_admin_role = ():void => {

	    if(init_cnt.current >1) return;
	    
	    if(DEBUG) console.log(TAG+'entering init_user_admin_role: init_cnt.current='
		+ init_cnt.current);
	    try {
		if( user &&  user.emailVerified) {	    
		    user.getIdTokenResult(true).then(set_user_admin_role).catch((error:string)=>{
			console.log(TAG+error);
		    })
		}
	    } catch(error) {
		console.error(TAG+'init_user_admin_role: error=' + error);
	    }
	}

	const fetch = ():void => {

	    if(ref.current != null) return;
	    
	    if(DEBUG) console.log(TAG+'*** entering fetch');
	    if( user &&  user.emailVerified) {

		//if(DEBUG) console.log(TAG+'fetch: PT1');

		setIsLoading(true);
		const session_token = get_session_token();

		//if(DEBUG) console.log(TAG+'fetch: PT2');
		
		server.handle_get_user_licenses_info({session_token}).then(
		    (x)=> {
			//if(DEBUG) console.log(TAG+'fetch: PT3');

			if(x.status ===MsgStatus.OK) {

			    //if(DEBUG) console.log(TAG+'fetch: PT3a');
			    
			    if(ref.current==null || ! equal(x.user_licenses_info,ref.current)) {
				setUserLicensesInfo(x.user_licenses_info);
				ref.current = x.user_licenses_info;
				
			    }

			    //if(DEBUG) console.log(TAG+'fetch: PT3b');			    
			    //init_user_admin_role();
			    
			} else {
			    //if(DEBUG) console.log(TAG+'fetch: PT2a');			    			    
			    if(userLicensesInfo) setUserLicensesInfo(null);			    
			}
			setIsLoading(false);
		    }
		).catch(
		    ()=>{
			if(DEBUG) console.log(TAG+'fetch: PT4');			
			if(userLicensesInfo) setUserLicensesInfo(null);
			setIsLoading(false);			
		    }
		);
		
	    } else {
		if(DEBUG) console.log(TAG+'fetch: PT5');					
		if(userLicensesInfo) setUserLicensesInfo(null);
	    }
	}

	/*
	React.useEffect( ()=> {
	    const auth = getAuth();
	    //auth.onIdTokenChanged((xuser:UserRecord) => {
	    auth.onIdTokenChanged(() => {
		//init_cnt.current = 0;//!!!!!
		////init_user_admin_role();
	    });
	},[user]);
	*/
	
	React.useEffect( ()=> {
	    console.log(TAG+'init_cnt='+init_cnt.current);
	    if(init_cnt.current ===0) fetch();
	    else setUserLicensesInfo(ref.current);
	    init_cnt.current++;
	    init_user_admin_role();	    
	},[]);	

	const refresh_user_licenses_info = () : void => {
	    console.log(TAG+'entering refresh_user_licenses_info');
	    ref.current =  null;	    
	    fetch();
	}

	const purchase_info =  userLicensesInfo==null ? null : userLicensesInfo.purchase_info;

	const navigate = useNavigate();
	
	const onTimeout = ():void => {
	    
	    const auth = getAuth();
	    
	    firebase_auth.signOut(auth).
		then(() => {
		    LOGGER.info(DEBUG, TAG+'timeout idle: logging out');
		    clear_reg_logger_session_token();
		}).
		catch( (error) => {
		    LOGGER.error(TAG+'timeout idle: logging out error=' + String(error));
		    console.error(error);
		});
	    navigate(OLDPUB_SIGNIN_ENDING_URL);
	}

	const onTimeoutMsg = (secs : number):string=> {
	    return `Logging out in ${secs} secs due to inactivity.`;
	}
	
    return (
        <MainPagesContext.Provider value={{
	    user_admin_role:userAdminRole,
	    refresh_user_licenses_info,
	    user_licenses_info : userLicensesInfo,
	    is_loading:isLoading,
	    purchase_info:purchase_info}}>
          <SessionTimeout onTimeout={onTimeout}
			  onTimeoutMsg={onTimeoutMsg}
	  />
            {props.children}
        </MainPagesContext.Provider>
    )
}
