// File: not_found.tsx
//

import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { OLDPUB_HOME_URL } from '../../extlinks/portal_comm_types';

export function NotFound():React.ReactElement {
    return (

 <Container component="main">

        <Typography sx={{mt:10, pl:0}} variant={'h4'} align="center">
Oops! You seem to be lost.
         </Typography>

        <Typography  sx={{mt:3}} variant={'h6'}  align="center">
           <Link href={OLDPUB_HOME_URL} style={{textDecoration:'none'}}>    
Log In or Go to Home Page
           </Link>    
        </Typography>    

  </Container>    
    )
}


