// File: mobile_upgrade_layout.tsx
//

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Icon from '@mdi/react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright} from '../../../utils_common/copyright';
import { TDBAppLogoVeryLarge } from '../../../utils_common/tdb_large_logo';
import Typography from '@mui/material/Typography';
import { Spinner } from '../../../utils_common/spinner';
//import useMediaQuery from "@mui/material/useMediaQuery";
import { mdiHomeOutline }  from '@mdi/js/mdi'
import {useLocation, useNavigate } from 'react-router-dom';
import { ECOMM_AUTH } from '../../../routes/paths';

//import { useMainPagesContext } from './pub_main_pages_provider';
//import { PubMainPagesContextState } from './pub_main_pages_provider';//
//import { UserPurchaseInfo } from '../../extlinks/portal_comm_types';

//const TAG='main_layout.tsx: ';
//const TAG='mobile_upgrade_layout.tsx: ';

const HOME=ECOMM_AUTH.LOGIN_URL;

export interface MainLayoutProps {
    title : string,
    dont_refresh_user_info?:boolean,
    children : React.ReactElement[] | React.ReactElement
}

const theme = createTheme();


function TopAppBar(props : {title:string, openMenu : boolean,
    //setAnchorEl : ((e:HTMLElement|null)=>void),
    setOpenMenu:(open:boolean)=>void}) : React.ReactElement {
    
    const handleDrawerToggle = (evt : React.MouseEvent<HTMLElement>) :void => {
	//props.setAnchorEl(evt.currentTarget);
	props.setOpenMenu( ! props.openMenu );
    }

	const anchorEl = React.useRef<HTMLElement|null>();

	React.useEffect( ()=> {
	    if(anchorEl.current !== undefined) {
		//props.setAnchorEl(anchorEl.current);
	    }
	},[]);
	
    return (<>
      <AppBar
	  component='nav'
	  style={{
	      width: '100%' ,
	      top:'0',
	      zIndex:10,
	      backgroundColor : "#8e0d10",
	      display:'flex', flexDirection:'row', 
	      justifyContent: 'flex-start', alignItems : 'center'}}
          sx={{ pr:2,
          width: '100vw' 
        }}
	  position='fixed'
      >
	
	<Box style={{paddingLeft:'2em'}}   position='relative'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={(evt)=>{handleDrawerToggle(evt)} }
            sx={{ mr: 2, }}
          >
             <MenuIcon />
          </IconButton>
        </Box>

        <Toolbar  style={{fontSize: "1.2em", marginLeft:'auto', marginRight:'auto'}}>
	  {props.title}
	</Toolbar>


	</AppBar>
	</>
    )
}

export function MobileUpgradeLayout(props : MainLayoutProps) : React.ReactElement {
    
    //const main_pages_state = useMainPagesContext();
    //const waiting = main_pages_state.purchase_info==null;
    const waiting = false; //!!!!!!

    const refcnt = React.useRef<number>(0);

    React.useEffect( ()=>{
	refcnt.current++;
    },[]);

    //const isMobile = useMediaQuery(theme.breakpoints.up("xs"));
    
    const [menuOpen, setMenuOpen] = React.useState(false);

    const navigate = useNavigate();

    const location = useLocation();

    const goToHome = ():void => {
	console.log('goToHome');
	console.log('location=',location);	
	navigate(HOME);
    }

    //const [anchorEl, setAnchorEl] = React.useState<HTMLElement|null>(null);

    return (<>
	
    <ThemeProvider theme={theme}>

    <CssBaseline/>
      
    <Box sx={{ height:'100vh',width:'100vw', top:'0', position:'absolute', overFlow:'hidden'}} >
	
      <TopAppBar title={props.title}
		 openMenu={menuOpen} setOpenMenu={setMenuOpen} />

        <Toolbar/>

	<Box style={{width:'100%', height:'1em'}}/>
	
        <Box sx={{ paddingLeft: { xls:'0.5em', sm: '5em' },
              paddingRight:{xls: '0.5em', sm:'5em'},
	           zIndex:1,

	           width:'100%'
           	  }}
	     style={{zIndex:1}}
             component="div"
           >	    

          <>{ location.pathname !==HOME	 &&	  
              (<Box component='div'

		   style={{padding:'0',margin:'0', width:'100%', right:'2em', 
		   display:'flex', justifyContent:'flex-end'}}
	      >

               <IconButton onClick={goToHome}
			   sx={{p:0,mt:1}}
	       >		
                <Icon path={mdiHomeOutline}  size={1.3}/>
               </IconButton>
		    
           </Box>)}</>
	      
    	      <Typography align='center' sx={{mt:0, mb:2}}>	  
	         <TDBAppLogoVeryLarge/>
	      </Typography>	     
	     
	      <Box style={{height:'0.75em'}}/>

	      <Box>	      
	              {props.children}
                 <Copyright  sx={{ mt: 4, mb: 4 }}/>
              </Box>

      </Box>	

   </Box>
   
   <Spinner open={waiting} />    
   </ThemeProvider>
</>
  );

}
