// File: mobile_licenses_table.tsx
//

import * as React from 'react';
import { ECommAuth} from '../../../extlinks/portal_comm_types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_BG_COLOR } from '../../utils/utils_config';

const TAG='mobile_licenses_table.tsx: ';
const DEBUG=true;

type MobileLicense = ECommAuth.ECommGetUserLicensesInfo.MobileLicense;

const Para = (props:{text:string}) : React.ReactElement => {
    return(<>
	<p style={{lineHeight:'100%', marginTop:'0.5em', marginBottom: '0.5em'}} >{props.text}</p>
	</>
    )
}

export function MobileLicensesTable(props:{mobile_licenses : MobileLicense[]}) : React.ReactElement {

    
    
    return(<>
    <TableContainer component={Paper} 
		    sx={{boxShadow:3, maxWidth:'97vw', borderRadius:'10px', pb: 2}}>
    
    <Table style={{maxWidth:'97vw'}}>
	
      <TableHead style={{borderRadius:'10px', backgroundColor:TABLE_HEADER_BG_COLOR}}>

	<TableRow>	  	
	  <TableCell
	      style={{color:TABLE_HEADER_FONT_COLOR}} colSpan={6}
	      align="center">Your Mobile Licenses</TableCell>
	</TableRow>	  
	
	<TableRow>
	  <TableCell  size="small" 
 		style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left"><Para text="Activation"/><Para text="Code"/></TableCell>


	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left"><Para text="Max No."/><Para text="Devices"/></TableCell>

	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left">
	    <Para text="Max No."/><Para text="Activations"/>
            <Para text="per Device"/></TableCell>

	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left">Issued</TableCell>


	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left">Expires</TableCell>	  
	</TableRow>

      </TableHead>
      <TableBody>{props.mobile_licenses.map( (lic, index) =>  {

	      const issue_date = lic.issue_date	== null ? '' :
		      (new Date(lic.issue_date)).toLocaleDateString();

	      const expiry_date = lic.expiration_date == null ? 'None' :
	              (new Date(lic.expiration_date)).toLocaleDateString();
	      
	      return (
		  <TableRow
		      key={String(index)}
		      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		  >
		    <TableCell size="small" align="left">{lic.activation_code}</TableCell>
		    <TableCell size="small" align="left">{lic.max_num_devices}</TableCell>		    
		    <TableCell size="small" align="left">{lic.max_act_per_device}</TableCell>
		    <TableCell size="small" align="left">{issue_date}</TableCell>
		    <TableCell size="small" align="left">{expiry_date}</TableCell>		    
		  </TableRow>
	      );
	  })}</TableBody>	      
    </Table>
    </TableContainer>                      
        </>);
}
