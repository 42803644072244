// File: view_mobile_devices.tsx
//

import * as React from 'react';
import { MainLayout} from '../utils/main_layout';
import  Box  from '@mui/material/Box';
import  Button  from '@mui/material/Button';
import  CircularProgress  from '@mui/material/CircularProgress';
import Backdrop  from '@mui/material/Backdrop';
import { MobileActivatedDevicesTable } from '../utils/mobile_activated_devices';
import { useMainPagesContext } from '../utils/pub_main_pages_provider';
import Typography from '@mui/material/Typography';
import { useNavigate} from 'react-router-dom';
import { LOGGER } from '../../utils_common/logger';
import { OLDPUB_HOME_URL } from '../../extlinks/portal_comm_types';

const DEBUG=false;
const TAG='display_mobile_devices.tsx: ';

export function  DisplayMobileDevices() : React.ReactElement {

    const count = React.useRef<number>(0);
    {
	const navigate = useNavigate();	
	count.current = count.current+1;
	if(DEBUG) console.log(TAG+'***count='+count.current);
	if(count.current > 50) navigate(OLDPUB_HOME_URL);
    }

    if(count.current ===1) {
	LOGGER.log_event('ENTER_DISPLAY_MOBILE_DEVICES',
	    'display_mobile_devices.tsx');
    }
    
    const [start, setStart] = React.useState<boolean>(false);
    React.useEffect( ()=>{ setStart(true); });
    
    const main_pages_state = useMainPagesContext();
    const user_licenses_info =  main_pages_state.user_licenses_info;
    const mobile_device_infos = user_licenses_info == null ? [] :
	user_licenses_info.mobile_device_infos;
    
    const refresh_fun = ():void => {
	main_pages_state.refresh_user_licenses_info();
    }
    
    const isLoading = main_pages_state.is_loading;
    
    return (
   <MainLayout title='Mobile Devices'>	

      <Backdrop
	  invisible={true} open={isLoading} sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	 <CircularProgress/>
      </Backdrop>

      <>
      
      { (start && mobile_device_infos != null && mobile_device_infos.length===0 && ! isLoading ) &&
	  
	    (<>
    	      <Typography align='center' variant="body1">
	      You have no activated mobile devices.
              </Typography>

	      <Box sx={{minHeight:'5rem',mt:2}} textAlign='center'>

		<Button
		    size="small"	    
		    type="submit"
		    variant='outlined'	  	    
		    sx={{ mt: 2, mb: 2}}
		    onClick={()=>refresh_fun()}
		>
	     REFRESH INFO
		</Button> 	  
	      </Box>
	      
            </>) 
      }

	
      { (start && mobile_device_infos != null && mobile_device_infos.length>0 && !isLoading ) &&
	  
	  (
	    <Box sx={{minHeight:'5rem',mt:2}} textAlign='center'>
	    
	      <Box>     
		<MobileActivatedDevicesTable mobile_device_infos={mobile_device_infos}
					     key={'A'}/>
	      </Box>

              <Button
		  size="small"	    
		  type="submit"
		  variant='outlined'	  	    
		  sx={{ mt: 2, mb: 2}}
		  onClick={()=>refresh_fun()}
              >
	    REFRESH INFO
              </Button> 	  
	    </Box>	  )
      }

	</>
  </MainLayout>	
  );
}
