// File: admin_cannot_change_email.tsx
//

import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MainLayout} from '../utils/main_layout';
import { LOGGER } from '../../utils_common/logger';
import { OLDPUB_HOME_URL } from '../../extlinks/portal_comm_types';

const TAG='change_email.tsx: ';

export function AdminCannotChangeEmail() : React.ReactElement {

    const count = React.useRef<number>(0);
    {
	const navigate = useNavigate();	
	count.current = count.current+1;
	if(count.current > 50) navigate(OLDPUB_HOME_URL);
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_ADMIN_CANNOT_CHANGE_EMAIL','admin_cannot_change_email.tsx');
    }


    return (
	
	<MainLayout title=''>
	      
	  <Box sx={{ marginTop:'2em',display: 'flex',
	      flexDirection: 'column',
	      justifyContent: 'space-between',
	      alignItems:'center' }}>
	    <Typography sx={{fontSize:'1.3em'}}>
	      Admin users are not allowed to change their login email. 
	      	    </Typography>
	    <Typography sx={{mt:2, fontSize:'1.3em'}}>
	An admin with manager authority will have to make your account no longer an admin account
	      before the login email can be changed.
	      	    </Typography>
	  </Box>

	</MainLayout>      
    );
}



