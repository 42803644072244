// File: logout_dialog.tsx
//
 
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import * as firebase_auth from 'firebase/auth';
import {  LOGGER } from './logger';
import { clear_reg_logger_session_token } from './reg_logger_session_token';

const TAG='logout_dialog.tsx: ';
const DEBUG=false;

export function LogoutDialog(props :
    { signInUrl:string,
	open  : ()=>boolean,
      close : ()=>void}) : React.ReactElement {

    const auth =  firebase_auth.getAuth();
    
    const navigate = useNavigate();
    
    const logout = ():void => {
        firebase_auth.signOut(auth).
            then(() => {
		LOGGER.info(DEBUG,TAG+'logging out');
		clear_reg_logger_session_token();
		navigate(props.signInUrl)
	    }).
            catch( (error) => {
		LOGGER.error(TAG+'logging out error=' + String(error));
		console.error(error)
	    });
	props.close();
    }

  return (

      <Dialog
        open={props.open()}
        onClose={()=>props.close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
      Are you sure you want to logout?
        </DialogTitle>

        <DialogActions sx={{ display:'flex', flexDirection:'row',
	    justifyContent:'space-around' }}>
          <Button onClick={()=>props.close()}>
	    Cancel
	  </Button>
          <Button onClick={()=>logout()} autoFocus>
	    Logout
	  </Button>
        </DialogActions>
	
      </Dialog>
  );
}

