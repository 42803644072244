// File: mobile_upgrade_launch_popup.tsx
//

import * as React from 'react';
import { z } from 'zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getAuth } from 'firebase/auth';
import { loadScript } from '../../utils/load_script';
import { useMainPagesContext } from '../../utils/pub_main_pages_provider';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { MainLayout } from '../../utils/main_layout';
import { SideBarType } from '../../utils/side_bar';
import { useLocationState } from '../../../utils_common/use_location_state';
import { MobileUpgradeState } from './mobile_upgrade';
import { getServerComm } from '../../utils/server_comm';
import {
    //    GetUpgradePopupSession,
    PopupCancelOrderStatus,
    PopupCreateMobileUpgradeLicense,
    MsgStatus,
    MOBILE_UPGRADE_PRICE_USD,
    OLDPUB_HOME_URL,
    OLDPUB_ACTIVATE_MOBILE_URL
} from '../../../extlinks/portal_comm_types';
import { AlertDialogProps, AlertDialog } from '../../../utils_common/alert_dialog';
import { useNavigate } from 'react-router-dom';
import { get_session_token, LOGGER } from '../../../utils_common/logger';

const TAG='mobile_upgrade_launch_popup.tsx: ';
const DEBUG=false;

//type SecurePayload = GetUpgradePopupSession.SecurePayload;

const WebhookCallBackSchema = z.object({
    id : z.string(),
    reference : z.string(),
    items : z.array(
	z.object({
	    product : z.string(),
//	    productPath:z.string(),
	    sku : z.string()
	})
    ),
    total:z.number(),
    subtotal:z.number()
});

type WebhookCallBack = z.infer<typeof WebhookCallBackSchema>;
    
function load_fs_script(loadSuccess:()=>void, loadFailed : ()=>void) : ()=>void {
    
    const src="https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js";
    
    const { script_elem, remove_listeners} =
	loadScript(src, loadSuccess, loadFailed);
    
    script_elem.id="fsc-api";
    
    script_elem.setAttribute('data-access-key',	    
	    "L_AKFT1WRKG11XDCN0-WUQ")
	
    script_elem.setAttribute('data-storefront',
	    "helpsministries.test.onfastspring.com/popup-mobile-upgrade-test");
	
    script_elem.setAttribute('data-popup-webhook-received', "dataPopupWebhookReceived");    

    return remove_listeners;
}

import CancelOrderStatus =  PopupCreateMobileUpgradeLicense.CancelOrderStatus;


function get_cancel_order_err_msg(status : PopupCancelOrderStatus) : string {
    switch(status) {
	case PopupCancelOrderStatus.ORDER_MUST_NOT_BE_CANCELLED:
	    return '';
	case PopupCancelOrderStatus.ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED:
	    return 'Contact us to get your charges refunded.';	    
	case PopupCancelOrderStatus.ORDER_ALREADY_CANCELLED:
	    return 'If your account was charged, it will be automatically refunded.';	    
	case PopupCancelOrderStatus.ORDER_CANCELLED:
	    return 'Order will be automatically refunded to your account.';
    }
}

const CLOSED_DIALOG_PROPS : AlertDialogProps = {
    open : ()=>false,
    handle_close : ()=>{},
    handle_choice1 :()=>{},
    handle_choice2 : ()=>{},
    title : '',
    text : '',
    choice1 : '',
    choice2 : ''
}


// test error
//const TEST_ERROR = '11';
const TEST_ERROR = '-1';
//const TEST_ERROR = '1';

//TODO:
// -- need instructions on  upgrade ??
//
export function MobileUpgradeLaunchPopup() : React.ReactElement {
    
    const navigate = useNavigate();

//    const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);
    
    const count = React.useRef<number>(0);
    {

	count.current = count.current+1;
	if(DEBUG) console.log('***count='+count.current);
	if(count.current > 50) navigate(OLDPUB_HOME_URL);
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_MOBILE_UPGRADE_LAUNCH_POPUP_PAGE','mobile_upgrade_launch_popup.tsx');
    }
    
    const [ spin, setSpin ] = React.useState<boolean>(false);
    
    const main_pages_state = useMainPagesContext();
    const mainPagesIsLoading = main_pages_state.is_loading;

    const [ dialogProps, setDialogProps ] =
	React.useState<AlertDialogProps>(CLOSED_DIALOG_PROPS);

    const close_dialog = () : void => {
	setDialogProps(CLOSED_DIALOG_PROPS);	
    }
    
    // note: have to return after call
    const open_dialog = (title:string,	txt : string,
	ok_close_hook : (()=>void)) : void => {
	    LOGGER.log_event('MOBILE_UPGRADE_POPUP_PAGE_OPEN_DIALOG',
		'title='+title);
	let open = true;
	setDialogProps({
	    open: ()=>{ return open; },
	    handle_close : close_dialog,
	    handle_choice1 : ()=>{ok_close_hook(); close_dialog() },
	    handle_choice2 : close_dialog,
	    title : title,
	    text : txt,
	    choice1 : 'OK',
	    choice2 : ''
	});
    }


    const order_cancelled_dialog =
	(dialog_msg:string, status : PopupCancelOrderStatus, ok_close_hook : ()=>void): void => {
	    LOGGER.log_event('MOBILE_UPGRADE_POPUP_PAGE_OPEN_CANCEL_DIALOG',
		'title='+dialog_msg+' status='+String(status));
	    let open = true;
	    //setDialogOpen(true);
	    setDialogProps({
		open: ()=>{ return open; },
		handle_close : close_dialog,
		handle_choice1 : ()=>{ok_close_hook(); close_dialog(); },
		handle_choice2 : close_dialog,
		title : dialog_msg,
		text : get_cancel_order_err_msg(status),	    
		choice1 : 'OK',
		choice2 : ''
	    });
    }

    //const [ currentEmail , setCurrentEmail ] = React.useState<string|null>(null);

    // useRef avoids infinite rerenders
    const loaded = React.useRef(false);

    const server = getServerComm();
    
    let state : MobileUpgradeState|null = useLocationState<MobileUpgradeState>();

    //let state : MobileUpgradeState={parent_desktop_license_key:'abc',
    //is_mobile_upgrade_state:true}
    //console.log('PT0 state=',JSON.stringify(state,null,2));
    
    const parent_desktop_license_key = state?.parent_desktop_license_key;    

    React.useEffect( ()=> {

	console.log('useEffect PT1: state=',state);
	
	if( !state || ! state.is_mobile_upgrade_state  ||
	    parent_desktop_license_key==null || parent_desktop_license_key==='') {
	    LOGGER.error(TAG+'PT1 parent_desktop_license_key=' +
		parent_desktop_license_key);
	    navigate(OLDPUB_HOME_URL);
	    return;
	}
    },[]);

    const auth = getAuth();
/*    
    auth.onAuthStateChanged( user=> {
        if(user && user.email) {
	    setCurrentEmail(user.email);
        }
    });
  */
    
/*
    const user = auth.currentUser;
    React.useEffect( ()=> {
        if(user && user.email) {
	    setCurrentEmail(user.email);
        }	
    }, [user, user?.email]);


    React.useEffect( ()=> {    
	auth.onAuthStateChanged( user=> {
            if(user && user.email) {
		setCurrentEmail(user.email);
            }
	});
    },[]);
*/
    
    React.useEffect( ()=> {

	console.log(TAG+'useEffect PT2');

	const loadSuccess = ():void => {
	    loaded.current = true;
	    console.log(TAG+'loadSuccess: loaded=' + loaded.current);	    
	}
	
	const loadFailed = ():void => {
	    loaded.current =false;
	    LOGGER.error(TAG+'loadFailed');		    
	}

	return load_fs_script(loadSuccess,loadFailed);
 
    },[]);
    
    const service_purchase = async (data:WebhookCallBack):Promise<void>=>{

	/*
	if(TEST_ERROR.trim()==='11') {
	    service_dialog('TEST_ERROR'+TEST_ERROR,
		CancelOrderStatus.ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED,
		()=>{ navigate(OLDPUB_HOME_URL) });
	    return;
	}*/

	LOGGER.info(DEBUG, 'entering service_purchase: data=',data);

	const session_token = get_session_token();
	const license_status =
	    await server.handle_desktop_license_eligible_for_mobile_upgrade({
		session_token,
		desktop_license_key:  parent_desktop_license_key });

	if(license_status.status === MsgStatus.ERROR ||
	    ! license_status.is_valid) {
	    LOGGER.error(TAG+'license_status not valid: error: ' +
		license_status.error_message);
	    if(license_status.error_message) {
		open_dialog(license_status.error_message,'',		    
		    ()=>{ navigate(OLDPUB_HOME_URL) });
	    } else {
		open_dialog('license not valid or not eligible','', ()=>{ navigate(OLDPUB_HOME_URL) });
	    }
	    return;
	}

	//console.info(TAG+'service_purchase: PT1: data=',data);

	const xdata = data;

	/*
	const xdata = data as {
	    id : string,
	    reference : string,
	    items : {
		product : string,
		//productPath:string,
		sku : string
	    }[],
	    total:number,
	    subtotal:number
	};*/

	if(xdata.items.length !== 1) {
	    LOGGER.error(TAG+'xdata.items.length !== 1');
	    open_dialog('error processing data from e-marketplace','',
		    ()=>{ navigate(OLDPUB_HOME_URL) });	    
	    return;
	}
	
	const product = data.items[0].product;
//	const product_path = xdata.items[0].productPath;	
	const sku = xdata.items[0].sku;

	const input : PopupCreateMobileUpgradeLicense.InType = {
	    session_token,
	    fs_order_id : xdata.id,
	    fs_order_reference : xdata.reference,
	    product_path:product,
	    sku,
	    parent_desktop_license_key : parent_desktop_license_key,
	    subtotal:xdata.subtotal,
	    total:xdata.total
	}
 	
	const response = await server.handle_popup_create_mobile_upgrade(input);
	
	if(response.status === MsgStatus.ERROR) {
	    LOGGER.error(TAG+'Server had error:'+response.error_message);
	    order_cancelled_dialog('Server had error.',
		response.cancel_order_status,
		()=>{});
	    return;
	}

	console.info(TAG+'service_purchase: PT2');
	
	if( ! response.is_valid) {
	    LOGGER.error(TAG+'Purchase was not valid:'+response.error_message);	    
	    order_cancelled_dialog('Purchase was invalid.',
		response.cancel_order_status,
		()=>{});
	    return;
	}

	LOGGER.log_event('MOBILE_UPGRADE_PURCHASE_SUCCESS',
	    'service_purchase: success');

	navigate(OLDPUB_ACTIVATE_MOBILE_URL);
	
	return;
    }
    
    // @ts-ignore
    window.dataPopupWebhookReceived = (data: WebhookCallBack)=>{

	//LOGGER.info(DEBUG,'entering dataPopupWebhookReceived: data=',data);
	LOGGER.info(DEBUG,'entering dataPopupWebhookReceived');

	try {
	    WebhookCallBackSchema.parse(data);
	} catch(error) {
	    LOGGER.error('dataPopupWebhookReceived: parse data: error=' + error);
	    LOGGER.error(TAG+'dataPopupWebHookReceived: data=',data);
	    console.error(TAG+'dataPopupWebHookReceived: error=' + error);
	    open_dialog('internal error', '',()=>{});
	    return;
	}

	//console.log(TAG+'dataPopupWebHookReceived: data=',data);
	
	service_purchase(data).then( ()=> {
	} ).catch( (error)=>{
	    LOGGER.error(TAG+'dataPopupWebHookReceived: error=' + error);
	    open_dialog('error making order',
		'',()=>{});
	});
    }

    const checkout_impl = async ():Promise<void> => {

	LOGGER.info(DEBUG, TAG+'entering checkout_impl');

	if(TEST_ERROR.trim() === '1') {

	    /*
	    open_dialog('TEST_ERROR'+TEST_ERROR,'',
		()=>{ navigate(OLDPUB_HOME_URL)});

	    */

	    order_cancelled_dialog('TEST_ERROR'+TEST_ERROR,
		CancelOrderStatus.ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED,
		       ()=>{ navigate(OLDPUB_HOME_URL) });

	    return;	    
	}

	setSpin(true);
	
	const user = auth.currentUser;
	//if(currentEmail == null) {
	if( ! user) {
	    LOGGER.error('user==null');
	    open_dialog('could not obtain user','',()=>{navigate(OLDPUB_HOME_URL)});
	    setSpin(false);
	    return;
	}
	
	const currentEmail = user.email;

	if( ! currentEmail) {
	    LOGGER.error('currentEmail==null');
	    open_dialog('could not obtain email','',()=>{navigate(OLDPUB_HOME_URL)});
	    setSpin(false);	    
	    return;
	}

	const session_token = get_session_token();
	const license_status =
	    await server.handle_desktop_license_eligible_for_mobile_upgrade({
		session_token,
		desktop_license_key: parent_desktop_license_key });
	
	if(license_status.status === MsgStatus.ERROR ||
	    ! license_status.is_valid) {
	    LOGGER.error(TAG+'license_status not valid: error: ' +
		license_status.error_message);
	    if(license_status.error_message) {
		open_dialog(license_status.error_message,'',
		    ()=>{navigate(OLDPUB_HOME_URL)});
	    } else {
		open_dialog('license not valid or not eligible','',
		    ()=>{navigate(OLDPUB_HOME_URL)});
	    }
	    return;
	}

	const resp =
	    await server.handle_get_encrypted_session({
		session_token,
		customer_email : currentEmail,
		parent_desktop_license_key,
	    });
	
	if(resp.status=== MsgStatus.ERROR) {
	    LOGGER.error('resp.status=== MsgStatus.ERROR');
	    open_dialog('license not valid or server error','',()=>{});
	    setSpin(false);	    
	    return;
	}
	
	console.log('currentEmail='+currentEmail);
	
	console.log('PT1');

	// @ts-ignore		       
	window.fastspring.builder.secure(resp.encrypted_session.secured_data,
	    resp.encrypted_session.secured_key);
	
	console.log('PT2');
	
	setSpin(false);
	
	// @ts-ignore
	window.fastspring.builder.checkout();

	LOGGER.log_event('FS_MOBILE_UPGRADE_PURCHASE_SUCCESS','');

    }

//    const checkout = React.useCallback( ():void => {
    const checkout = ():void => {

	LOGGER.info(DEBUG,TAG+'entering checkout()');

	if( ! loaded.current) {
	    LOGGER.error(TAG+'exiting checkout():  loaded.current=false');
	    return;
	}
	
	checkout_impl().then( ()=> {}).
	    catch( (error)=> {
		LOGGER.error(TAG+'checkout: error='+error);
		open_dialog('checkout error','', ()=>{});
	    });
    }

//    },[count]);

    return (
	<MainLayout title='Mobile Upgrade Purchase'
		    dont_refresh_user_info={true}
		    side_bar_type={SideBarType.MOBUPGRADE_CHILD_PAGE}>
	  
	  <Box style={{paddingLeft: "1em",paddingRight: "2em", display:"flex",flexDirection:"column", alignItems:"center"}}>

	  <Box component="div" alignItems="center">
	    The purchase price of the mobile upgrade is <b>{MOBILE_UPGRADE_PRICE_USD} US dollars</b>.
    </Box>
    
	  <Box component="div" alignItems="center" sx={{}}>
	    
      <ol>
	 <li>    Press the CHECKOUT button below to make the purchase.</li>
	 
         <li style={{marginTop:"1em"}}>A checkout form will open from our e-commerce vendor <b><i>Fastspring</i></b>.</li>

          <li style={{marginTop:"1em"}}>
                   You may cancel at anytime by clicking the X in the
                   upper right corner of the form.   </li>

     </ol>
	  </Box>
	  
           <Box sx={{mt:1}}>
	      <center><Typography sx={{fontWeight:'bold'}}>Do not change the email on the checkout form.</Typography></center>
          </Box>


	  
	  </Box>
	  
	  <Box 	      sx={{mt:2}}
	      style={{width:'100%',
	      display:'flex', flexDirection:'column',
	      justifyContent:'center', alignItems:'center'}}>

	    <Button  type="submit" variant="contained" size="small" onClick={()=>checkout()} >
	              Checkout
	    </Button>

          </Box>

	   <AlertDialog {...dialogProps} />

	  <Backdrop invisible={true} open={mainPagesIsLoading || spin}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>

	  

	</MainLayout>
    )
	
}

/*
function get_session(customer_email : string) : object {
 
    return {
	"reset": true,
	"products" : [
	    {
		"path":"discovery-bible-mobile-app-upgrade-offer",
		"quantity": 1
	    }
	],
	"paymentContact": {
	    "email": customer_email,
	},
	"language":"en"
    };
    
}

function get_partial_session(customer_email : string) : object {

    return {
	"reset": true,
	"paymentContact": {
	    "email": customer_email
	},
	"language":"en"
    };
    
}

function get_secure_payload(email : string) : object {
    
    return {
	"contact": {
	    "email": email,
	    "firstName":"John",
	    "lastName":"Doe"
	},
	"items": 
	    [
		{
		    "product": "discovery-bible-mobile-app-upgrade-offer",
		    "quantity": 1,
		    "pricing": {
			"price": {
			    "USD": 99.00
			}
		    }
		}
	    ]
    };
}
*/
