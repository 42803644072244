// File: auth_provider.tsx
//

import React from 'react';
import { getAuth,  User } from 'firebase/auth';

const TAG='auth_provider.tsx: ';
const DEBUG=false;

export interface AuthContextState {
    user: User|null;
    refresh: ()=>void;
}
export const AuthContext : React.Context<AuthContextState>
         = React.createContext<AuthContextState>({ user : null, refresh:()=>{} });

export function AuthProvider(
    props : { children : React.ReactElement | React.ReactElement[]}) : React.ReactElement {
	
	const [ currentUser , setCurrentUser] = React.useState<User|null>(null);

	const refresh = ():void => {

	    if(DEBUG) console.log(TAG+'entering refresh');
	    
	    const auth = getAuth();
	    const user = auth.currentUser;
	    if(user) setCurrentUser(user);
	}
	
	React.useEffect( ()=> {

	    const auth = getAuth();

	    if(auth!=null) {
		console.error(TAG+'auth==null');
	    }

	    auth.onIdTokenChanged((user) => {
		if(user) setCurrentUser(user);
	    });
	    
	    const unsubscribe =
		auth.onAuthStateChanged((usr:User|null)=> {
		    try {
			
			if(DEBUG) console.log(TAG+'onAuthStateChanged');
			setCurrentUser(usr);

		    } catch(error) {
			console.error(TAG+'onAuthStateChanged: error='+error);
		    }
		});

	    return unsubscribe;
	    
	},[]);

    return (
        <AuthContext.Provider value={{ user: currentUser, refresh:refresh }}>
            {props.children}
        </AuthContext.Provider>
    )
}

