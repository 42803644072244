// File: msg_dialog.tsx
//

import * as React from 'react';
import { AlertDialogProps, AlertDialog} from './alert_dialog';

interface MsgDialogSimpleProps {
    msg:string,
    service_close : ()=>void,   // has to set props.msg to '' to close dialog
    service_button1? :  ()=>void,
    service_button2? : ()=>void,
    button_label1 ? : string,
    button_label2 ? : string,        
}

interface MsgDialogState {
    close:boolean
}

export class MsgDialogSimple extends React.Component<MsgDialogSimpleProps,MsgDialogState> {
    
    constructor(props : MsgDialogSimpleProps) {
	super(props,{});
    }

    render() : React.ReactNode {

	//console.log('this.props=',this.props);
	
	const get_msg_dialog_is_open = () :boolean=> {
	    return this.props.msg !== '';
	}
	
	const handle_choice1 = () : void => {
	    if(this.props.service_button1) this.props.service_button1();
	    this.props.service_close();
	}

	const handle_choice2 = () : void => {
	    if(this.props.service_button2) this.props.service_button2();
	    this.props.service_close();
	}

	const close_msg_dialog = ():void => {
	    this.props.service_close();	    
	}
	
	const msg_dialog_props : AlertDialogProps = {
	    open : get_msg_dialog_is_open,		
	    handle_close : close_msg_dialog,
	    handle_choice1 : handle_choice1,
	    handle_choice2 : handle_choice2,
	    title : this.props.msg,
	    text : '',
	    choice1 : this.props.button_label1 ? this.props.button_label1 : 'Cancel',
	    choice2 : this.props.button_label2 === undefined ? "" : this.props.button_label2,
	}
	
	return (
	    <AlertDialog {...msg_dialog_props} />
	);
    }
}


export interface MsgDialogProps {
    msg:string,
    // called upon close (after either button pushed)
    // needs to set msg to '' so dialog will actually close
    close : ()=>void,   
    text?:string,    
    button1_handle? :  ()=>void, // called if first button is clicked
    button2_handle? :  ()=>void, // called if second button is clicked
    button1_label : string,
    button2_label ? : string,        
}

export class MsgDialog extends React.Component<MsgDialogProps,MsgDialogState> {
    
    constructor(props : MsgDialogProps) {
	super(props,{});
    }

    render() : React.ReactNode {

	//console.log('this.props=',this.props);
	
	const get_msg_dialog_is_open = () :boolean=> {
	    return this.props.msg !== '';
	}
	
	const handle_choice1 = ():void=> {
	    if(this.props.button1_handle) this.props.button1_handle();
	    this.props.close();
	}

	const handle_choice2 = ():void=> {
	    if(this.props.button2_handle) this.props.button2_handle();
	    this.props.close();	    
	}	

	const handle_close = ():void => {
	    if(this.props.close!=null) this.props.close();
	}
	
	const msg_dialog_props : AlertDialogProps ={
	    open : get_msg_dialog_is_open,		
	    handle_close,
	    handle_choice1,
	    handle_choice2,
	    title : this.props.msg,
	    text : '',
	    choice1 : this.props.button1_label,
	    choice2 : this.props.button2_label ? this.props.button2_label : ''
	}
	
	return (
	    <AlertDialog {...msg_dialog_props} />
	);
    }
}

