// File: check_url_params.ts
//

import { NavigateFunction } from 'react-router-dom';
import { getECommAuthServerComm } from './ecomm_auth_server_comm';
import { MsgStatus } from '../../extlinks/portal_comm_types';
import { ECOMM_AUTH } from '../../routes/paths';

const TAG='check_url_params.ts: ';
const DEBUG=true;

export function check_url_params(args:{url_params:string|undefined,
                                       parent_tag:string,
                                       navigate:NavigateFunction,
                                       finished?:()=>void}) : void {
    if(args.url_params===undefined) {
        console.error(args.parent_tag+'check_url_params: url_params==undefined')
        args.navigate(ECOMM_AUTH.NOT_FOUND_URL);
        return;
    }

    const server = getECommAuthServerComm();
    server.handle_check_ecomm_url_params({url_params:args.url_params}).then( result=> {
	if( ! result.ok || result.status===MsgStatus.ERROR) {
	    console.error(args.parent_tag+'check_url_params: error');
            args.navigate(ECOMM_AUTH.NOT_FOUND_URL);            
	}
        if(args.finished) args.finished();
    }).catch((error)=> {
	console.error(args.parent_tag+'check_url_params: error='+error);
        args.navigate(ECOMM_AUTH.NOT_FOUND_URL);                    
        if(args.finished) args.finished();        
    });    
}

export async function url_params_is_ok(args:{url_params:string|undefined}) : Promise<boolean> {
    
    if(args.url_params===undefined) {
        return false;
    }
    const server = getECommAuthServerComm();

    try {
        
        const result =
            await server.handle_check_ecomm_url_params({url_params:args.url_params});
        
        if( ! result.ok || result.status===MsgStatus.ERROR) {
            console.info(TAG+'url_params_is_ok: invalid url_params='+args.url_params);
            return false;
        } else {
            return true;
        }
        
    } catch(error) {
        console.error(TAG+'url_params_is_ok: error'+error);
        return false;
    }
}
