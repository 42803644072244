// File: routes_ecomm_auth.tsx
//

import * as React from 'react';
import {Route} from "react-router-dom";
//import { ECommAuthMobileUpgradeLanding } from "../pages_ecomm_auth/private/mobile_upgrade_landing";
import { ECommAuthLicenseInfoLanding } from "../pages_ecomm_auth/private/license_info_landing";
import { ECommAuthDesktopInstallInstructions } from '../pages_ecomm_auth/public/desktop_install';
import { ECommAuthMobileInstallInstructions} from '../pages_ecomm_auth/public/mobile_install';
import { ECommAuthRequireAuth } from "../pages_ecomm_auth/ECommAuthRequireAuth";
import { ECommAuthLogin } from '../pages_ecomm_auth/public/ecomm_auth_login';
import { ECommAuthEmailLinkLanding } from "../pages_ecomm_auth/public/email_link_landing";
import { ECommAuthNotFound } from "../pages_ecomm_auth/public/ecomm_not_found";
import { ECommAuthProvider } from "../pages_ecomm_auth/ecomm_auth_provider";
import { ECommAuthEmailNotVerified } from '../pages_ecomm_auth/public/ecomm_auth_email_not_verified';
import { ECommAuthInstructInstall } from '../pages_ecomm_auth/public/instruct_install';
import { ECOMM_AUTH } from './paths';
import { ECommAuthMobileUpgradeLaunchPopup } from '../pages_ecomm_auth/private/mobile_upgrade/mobile_upgrade_launch_popup';
import { ECommAuthPrivateLanding } from '../pages_ecomm_auth/private/private_landing';

const TAG='routes_ecomm_auth.tsx: ';

class GatherPaths {
    private GatherPaths_:undefined;
    readonly paths : string[] = [];
    add(path:string):string {
	this.paths.push(path);
	return path;
    }
}


function get_unprotected_routes(paths:GatherPaths):  React.ReactElement[] {

    let key=0;
    
    return [
	  
	<Route path={ECOMM_AUTH.NOT_FOUND_URL}
	       key={key++}	       
	       element={<ECommAuthNotFound/>}
	/>,

	<Route path={ECOMM_AUTH.EMAIL_NOT_VERIFIED_URL}
	       key={key++}	       
	       element={<ECommAuthEmailNotVerified/>}
	/>,	

	<Route path={ECOMM_AUTH.SLASH_URL}
	       key={key++}	       
	       element={<ECommAuthLogin />}
	/>,
	
	<Route path={ECOMM_AUTH.LOGIN_URL+'/:url_params'}
	       key={key++}	       
	       element={<ECommAuthLogin />}
	/>,

	<Route path={ECOMM_AUTH.LOGIN_URL}
	       key={key++}	       
	       element={<ECommAuthLogin />}
	/>,	
	
	<Route path={ECOMM_AUTH.EMAIL_LINK_LANDING_URL+'/:url_params'}
	       key={key++}	       
	       element={<ECommAuthEmailLinkLanding/>}
	/>,
	
	<Route path={ECOMM_AUTH.INSTRUCT_INSTALL_URL}
	       key={key++}	       
	       element={<ECommAuthInstructInstall/>}
	/>,

	<Route path={ECOMM_AUTH.INSTRUCT_INSTALL_URL+'/:show'}
	       key={key++}	       
	       element={<ECommAuthInstructInstall/>}
	/>,	
	  
	<Route path={ECOMM_AUTH.DESKTOP_INSTALL_INSTRUCT_URL}
	       key={key++}	       
	       element={<ECommAuthDesktopInstallInstructions/>}
	/>,
	  
	<Route path={ECOMM_AUTH.MOBILE_INSTALL_INSTRUCT_URL}
	       key={key++}	       
	       element={<ECommAuthMobileInstallInstructions/>}
	/>,	
	
    ];
}

function get_protected_routes(paths:GatherPaths):  React.ReactElement[] {

    let key=0;
    
    return [

	<Route path={ECOMM_AUTH.PRIVATE_LANDING_URL +'/:url_params'}
	       key={key++}	       
	    element={<ECommAuthPrivateLanding/>}
	/>,

	
	<Route path={ECOMM_AUTH.LICENSE_INFO_URL+'/:url_params'}
	       key={key++}	       
	    element={<ECommAuthLicenseInfoLanding/>}
	/>,

	<Route path={ECOMM_AUTH.MOBUPGRADE_LAUNCH_POPUP_URL+'/:url_params'}
	       key={key++}	       
	    element={<ECommAuthMobileUpgradeLaunchPopup/>}
	/>,	
    ]
}


export function check_ecomm_auth_routes() : void {

    const paths = new GatherPaths();
    
    get_unprotected_routes(paths);
    
    get_protected_routes(paths);

    let added_paths = paths.paths.sort().join(' ');
    console.log(TAG+'added_paths='+added_paths);
    
    let missing :string[]= [];
    for(let value of Object.values(ECOMM_AUTH)) {
	//console.log(TAG+'value='+value);
	if( ! (paths.paths.includes(value))) {
	    //console.error(TAG+'missing path='+value);
	    missing.push(value);
	}
    }

    console.log(TAG+'missing_paths='+missing.sort().join(' '));    
    if(missing.length>0) {
	//throw Error(TAG+'check_admin_routes: missing='+missing);
    }
}


export function ECommAuthRoutes() : React.ReactElement {
    const paths = new GatherPaths();
    return (
	<>
	  { get_unprotected_routes(paths) }
	
	<Route element={<ECommAuthProvider>
	                   <ECommAuthRequireAuth/>
       	                </ECommAuthProvider>} >

	  { get_protected_routes(paths) }

	</Route>
	
	</>
    );
}
