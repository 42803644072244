// File: tdb_large_logo.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import { TDB_WEBSITE_URL } from '../config/urls';

export function TDBAppLogoLarge() : React.ReactElement {
    
    const onClick = ():void => {
	window.location.href=TDB_WEBSITE_URL;
    }
    
    return( <>
      <Button sx={{ mt:0,mb:0,pt:0,pb:0}} onClick={onClick}>
      <img src={require('./tdb_applogo_large.png')} style={{transform: "scale(0.8)" }}  alt=""/>
      </Button>
      </>)
  
}



export function TDBAppLogoVeryLarge() : React.ReactElement {
    
    const onClick = ():void => {
	window.location.href=TDB_WEBSITE_URL;
    }
    
    return( <>
      <Button sx={{ mt:0,mb:0,pt:0,pb:0}} onClick={onClick}>
      <img src={require('./tdb_applogo_large.png')} style={{transform: "scale(1.1)" }}  alt=""/>
      </Button>
      </>)
  
}


