// File: pwless_signin_from_email_link.tsx
//
// signin from passwordless email verification link;
// the email for the link was either generated by user clicking on unique url
// or from the page in pwless_request_email_link.tsx
//

import * as React from 'react';
import { signInWithCustomToken } from 'firebase/auth'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {Link as RouterLink, Navigate, useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright} from '../../utils_common/copyright';
import { IPageProps } from '../interfaces/page'; 
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { AlertDialog, AlertDialog2 } from '../../utils_common/alert_dialog';
import { getAuth  } from 'firebase/auth';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import { useAuthStatus } from '../../auth_provider/use_auth';
import { useLocationState } from '../../utils_common/use_location_state';
import { MsgStatus, OLDPUB_FORGOT_PASSWORD_ENDING_URL, OLDPUB_PREHOME_ENDING_URL, OLDPUB_SIGNIN_ENDING_URL } from '../../extlinks/portal_comm_types';
import { getServerComm } from '../utils/server_comm';
import { UseMobUpgradeRoutes } from '../utils/use_mobupgrade_routes';
import {  useParams } from 'react-router-dom';
import { assertUnreachable } from '../../utils_common/assert_unreachable';

const TAG='pwless_signin_from_email_link.tsx: ';
const DEBUG=true;

const theme = createTheme();

export type SigninState = {
    email : string
}

export function PWLessSignInFromEmailLink( _props : IPageProps) : React.ReactElement {

    const url_token =  React.useRef<string|null>(null);
    const params = useParams();
    
    React.useEffect( ()=>{
	if(url_token.current == null) {
	    if(params.token !== undefined) {
		url_token.current = params.token;
		console.log('url_token.current=' + url_token.current);
	    }
	}

    },[])
    
    const auth = getAuth();

    const [ msg, setMsg ] = React.useState<string>('');

    const [ email, setEmail ] = React.useState<string>('');
    
    const [ spinner, setSpinner ] = React.useState<boolean>(false);

    const navigate = useNavigate();    

    const handleSubmitImpl = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {

	const tag = TAG+'handleSubmitImpl: ';
	
        setMsg('');

	if(url_token.current==null) {
	    console.error(TAG+'signin_and_send_link: url_token.current==null');
	    navigate(OLDPUB_SIGNIN_ENDING_URL);
	    return;
	}

	if(email.trim() === '') {
	    setMsg('Need to input email');
	    return;
	}

	const server = getServerComm();
	
	//if(DEBUG) console.info(TAG+'before signInWithEmailAndPassword: password=' + password);
	setSpinner(true);
	
	const result = await server.handle_pwless_get_signin_token_from_url_token( { url_token:url_token.current,
	    email:email.trim() });
		
	setSpinner(false);

	if(result.error_status != null) {
	    console.error(tag+'result.error_status=' +result.error_status);
	    switch(result.error_status) {
		    
		case 'ERROR_CREATING_ACCT' :
		    setMsg('Error creating account.');
		    return;
		case 'EMAIL_ALREADY_IN_USE':
		    setMsg('Email already in use.');		    
		    return;
		case 'EMAIL_NOT_ELIGIBLE':
		    setMsg('Email not eligible for new account.');		    		    
		    return;
		case 'INTERNAL_ERROR':
		    setMsg('Something went wrong.');
		    return;		    
		case 'SERVER_ERROR':
		    setMsg('Something went wrong.');		    		    		    		    
		    return;
		case 'SERVER_INTERNAL_ERROR':
		    setMsg('Server Error.');
		    return;
		case 'EMAIL_IS_FOR_ADMIN_USER':
		    setMsg('Admin user is not allowed to login by passwordless email link.');
		    return;		    
		case 'INVALID_URL_TOKEN':
		    setMsg('Email link has expired or is invalid.');
		    return;
	    }
	    assertUnreachable(result.error_status);
	}


	if(result.status === MsgStatus.ERROR) {
	    setMsg('Internal Error. (code=0)');
	    console.error(tag+'result.status === MsgStatus.ERROR');
	    return;	
	}

	if(result.signin_custom_token==null) {
	    setMsg('Internal Error. (code=1)');	    
	    console.error(tag+'result.signin_custom_token==null');
	    return;
	}
	
	const custom_token = result.signin_custom_token;
	
	const user_cred = await signInWithCustomToken(auth, custom_token);

	if(user_cred ==null) {
	    setMsg('Email link has expired or is invalid.');
	    console.error(tag+'user_cred==null')
	    return;
	} else {
	    
	    if(result.redirect_dest == null) {
		console.error(tag+'result.redirect_dest == null');
		navigate(OLDPUB_PREHOME_ENDING_URL,
		    {state : { from_signin_page:true }});			
	    }

	    console.log(tag+'result.redirect_dest=' + result.redirect_dest);
	    
	    switch(result.redirect_dest) {
		    
		case 'HOME':
		    
		    navigate(OLDPUB_PREHOME_ENDING_URL,
			{state : { from_signin_page:true }});
		    
		    return;
		    
		case 'MOBUPGRADE':

		    // TODO: should use state instead
		    UseMobUpgradeRoutes.set_on();		    
		    navigate(OLDPUB_PREHOME_ENDING_URL,
			{state : { from_signin_page:false }});
		    
		    return;
	    }
	}
	
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
	event.preventDefault();
	handleSubmitImpl(event).then(()=>{
	}).catch((error)=>{
	    console.error('handleSubmit: error=' + error);
	    setMsg('Internal Error. (code=2)')
	});
    }

    const dialog_is_open = () :boolean=> {
       return msg !== '';
    }

    const close_dialog = () : void => {
	setMsg('');
    }

    const dialog_props = {
	open : dialog_is_open,
	handle_close : close_dialog,
	handle_choice1 :close_dialog,
	handle_choice2 :close_dialog,

	title : msg,
	text : '',
	choice1 : 'OK',
	choice2 : ''
    }

    const  state  = useLocationState<SigninState>();

    React.useEffect( ()=> {
	if(state!=null && state.email) {
	    setEmail(state.email.trim().toLowerCase());
	}
    });

    
    const { user } = useAuthStatus();

    return (
      <>
	{ user != null && user.emailVerified
        ? (<Navigate to={OLDPUB_PREHOME_ENDING_URL}/>)
      	: (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

	<Typography align='center' sx={{mb:1}}>
         <TDBAppLogoLarge/>
	</Typography>
	 
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
	  
          <Box component="form"
	       onSubmit={(ev: React.FormEvent<HTMLFormElement>)=>handleSubmit(ev)}
	       noValidate sx={{ mt: 1 }}>
	    
            <TextField
		margin="normal"
		required
		fullWidth
		id="email"
		label="Email Address"
		name="email"
		autoComplete="email"
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setMsg('');
		    setEmail(e.target.value.trim().toLowerCase())}}	    
            />
	    
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item sx={{ mt: 0, mb: 1 }}>
                <RouterLink to={OLDPUB_FORGOT_PASSWORD_ENDING_URL}>
                  Forgot password?
                </RouterLink>
              </Grid>

            </Grid>
          </Box>
        </Box>

	<AlertDialog {...dialog_props} />

        <Copyright sx={{ mt: 5, mb: 4 }} />
      </Container>


	  <Backdrop invisible={true} open={spinner}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>

    </ThemeProvider>) }
      </>
  );
}

