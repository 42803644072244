// File: paths.ts
//

import { ECOMM_AUTH_PREFIX,
         ECOMM_AUTH_EMAIL_LINK_LANDING_URL,
         ECOMM_AUTH_INSTALL_INSTRUCT_URL,         
         ECOMM_AUTH_LOGIN_URL } from "../extlinks/portal_comm_types";

export namespace ADMIN {
    export const ADMIN_URL='/admin';    
    export const HOME_URL='/admin/home';
    export const SIGNIN_ENDING_URL='/admin/home';
    export const EMAIL_NOT_VERIFIED_ENDING_URL='/admin/email-not-verified';
    export const CHANGE_PASSWORD_ENDING_URL='/admin/change-password';
    export const CHANGE_EMAIL_ENDING_URL='/admin/change-email';
    export const LIST_ALL_LICENSES_URL='/admin/list-all-licenses';
    export const SEND_MOBUPGRADE_INVITES_URL='/admin/send-mobupgrade-invites';
    export const EDIT_ADMIN_LIST_URL='/admin/edit-admins';
    export const FREE_MOBILE_LICENSES_URL='/admin/free-mob-licenses';
    export const LIST_MOBUPGRADE_SEARCH_RESULTS_URL='/admin/list-mobupgrade-search-results';
    export const CANNOT_CHANGE_EMAIL_URL='/admin/cannot-change-email';

    export const URL='/admin';
    export const LIST_LICENSES_EMAIL_SEARCH_URL='/admin/list-licenses-email-search';
    export const EDIT_LICENSE_URL='/admin/edit-license';
}

export namespace ECOMM_AUTH {

    const PREFIX=ECOMM_AUTH_PREFIX;
    
    //export const HOME_URL=PREFIX+'/home';
    export const SLASH_URL=String(PREFIX)+'';
    export const LOGIN_URL=ECOMM_AUTH_LOGIN_URL;
    export const EMAIL_LINK_LANDING_URL=ECOMM_AUTH_EMAIL_LINK_LANDING_URL;
    export const NOT_FOUND_URL=PREFIX+'/not-found';
    export const EMAIL_NOT_VERIFIED_URL=PREFIX+'/email-not-verified';
    export const SELECT_EMAIL_URL=PREFIX+'/select-email';

    export const INSTRUCT_INSTALL_URL=ECOMM_AUTH_INSTALL_INSTRUCT_URL;
    export const MOBILE_INSTALL_INSTRUCT_URL=PREFIX+'/instruct-install/mobile';
    export const DESKTOP_INSTALL_INSTRUCT_URL=PREFIX+'/instruct-instal/desktop';

    export const PRIVATE_LANDING_URL=PREFIX+'/private';    
    export const LICENSE_INFO_URL=PREFIX+'/private/license-info';

    export const MOBUPGRADE_LANDING_URL=PREFIX+'/private/mobupgrade';
    export const MOBUPGRADE_LICENSES_STATUS_URL=PREFIX+'/private/mobupgrade/licenses-status';
    export const MOBUPGRADE_LITE_VER_LINKS_URL=PREFIX+'/private/mobupgrade/lite-ver-links';
    export const MOBUPGRADE_LAUNCH_POPUP_URL=PREFIX+'/private/mobupgrade/launch';
    export const MOBUPGRADE_DIRECT_URL=PREFIX+'/private/mobupgrade/direct';

    export const ACTIVATE_MOBILE_URL=PREFIX+'/activate-mobile';
}
