// File: ecomm_not_found.tsx
//

import * as React from 'react';
//import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export function ECommAuthNotFound():React.ReactElement {
    return (

 <Container component="main">

        <Typography sx={{mt:10, pl:0}} variant={'h4'} align="center">
Oops! You seem to be lost.
         </Typography>

  </Container>    
    )
}


