// File: server_comm.ts

import { getFunctions,  httpsCallableFromURL } from "firebase/functions";

import {
    ChangeAdminRole,
    AddNewAdmin,
    SendAdminInvite,    
    ListAllLicensesMsg,
    ListAdmins,
    GetEditLicenseInfo,
    ResetDeviceActivationCount,
    ModifyLicenseInfo,        
    MsgStatus,
    ResultType,
    ResponseType,
    AdminRequestType,
    AddFreeMobileLicenses,
    GetFreeMobileLicenses,
    SendMobUpgradeInvites,
    GetMobUpgradeableLicenses,
} from '../../extlinks/portal_comm_types';
import { get_firebase_functions_adminportalcomm } from '../../config/firebase_config';
 
const TAG='admin_server_comm.ts: ';
const DEBUG=true;
 
const PORTAL_FUN_URL=get_firebase_functions_adminportalcomm();

const TIMEOUT=360000;

async function query_server<TIn,TOut extends ResultType>(input : TIn,
                                                         cmd:string,
                                                         options? :{cancelExec? : ()=>boolean }) : Promise<TOut|null|string> {
    
    const functions = getFunctions();

    const portalFun = httpsCallableFromURL<AdminRequestType<TIn>,ResponseType<TOut>>(
        functions,
        PORTAL_FUN_URL,
        { timeout:TIMEOUT}
//        {limitedUseAppCheckTokens:true }   // is in beta
    );


    
    const msg_input:AdminRequestType<TIn> = { is_admin_request:true,  cmd:cmd,  input:input }
    
    return new Promise<TOut|string|null>( (resolve,reject) => {

        if(options && options.cancelExec) {
            setInterval( ()=> {
                if(options!=null && options.cancelExec && options.cancelExec()) {
                    //console.log(TAG+'cancelling');
                    resolve(null);
                }
            }, 5000);
        }
        
        portalFun(msg_input).then( (x) => {
            resolve(x.data.result);
        }).catch( (error) => {
            console.error(TAG+'query_server: error=' + String(error));
            console.error(TAG+'query_server: problems with appcheck for localhost?');                        
            //console.trace();
            //throw new Error(error);
            reject(String(error));
        });
    });
}

export class AdminServerComm {
    private AdminServerComm_:undefined;

    async handle_add_new_admin(input:AddNewAdmin.InType) : Promise<AddNewAdmin.OutType> {
        const out = await query_server<AddNewAdmin.InType,
                                       AddNewAdmin.OutType>(input, AddNewAdmin.CMD);

        if(out == null) {
            return AddNewAdmin.ERROR_OUT;
        }

        if(typeof out === 'string') {
            return AddNewAdmin.ERROR_OUT;            
        }

        return out;                              
    }


    async handle_send_admin_invite(input:SendAdminInvite.InType) : Promise<SendAdminInvite.OutType> {
        const out = await query_server<SendAdminInvite.InType,
                                       SendAdminInvite.OutType>(input, SendAdminInvite.CMD);

        if(out == null) {
            return AddNewAdmin.ERROR_OUT;
        }

        if(typeof out === 'string') {
            return SendAdminInvite.ERROR_OUT;            
        }

        return out;                              
    }

    
    async handle_change_admin_role(input:ChangeAdminRole.InType) : Promise<ChangeAdminRole.OutType> {
        const out = await query_server<ChangeAdminRole.InType,
                               ChangeAdminRole.OutType>(input, ChangeAdminRole.CMD);

        if(out == null) {
            return ChangeAdminRole.ERROR_OUT;
        }

        if(typeof out === 'string') {
            return ChangeAdminRole.ERROR_OUT;            
        }

        return out;                              
    }

    async handle_list_admins(input:ListAdmins.InType) : Promise<ListAdmins.OutType> {
        const out = await query_server<ListAdmins.InType,
                                       ListAdmins.OutType>(input,
                                                            ListAdmins.CMD);

        if(out == null || typeof out === 'string') {
            return ListAdmins.ERROR_OUT;
        }

        return out;                              
    }

    async handle_list_all_user_licenses(input:ListAllLicensesMsg.InType,
                                        options? :{cancelExec? : ()=>boolean }) : Promise<ListAllLicensesMsg.OutType> {
        
        const out = await query_server<ListAllLicensesMsg.InType,
                                       ListAllLicensesMsg.OutType>(input,
                                                                   ListAllLicensesMsg.CMD,
                                                                   options);

        //user_licenses_in_range_compress_b64
        
        if(out == null|| typeof out === 'string') {
            return ListAllLicensesMsg.ERROR_OUT;
        }

        return out;                              
    }

    async handle_get_edit_license_info(input:GetEditLicenseInfo.InType) : Promise<GetEditLicenseInfo.OutType> {
        const out = await query_server<GetEditLicenseInfo.InType,
                                       GetEditLicenseInfo.OutType>(input,
                                                            GetEditLicenseInfo.CMD);

        if(out == null || typeof out === 'string') {
            return {status : MsgStatus.ERROR,
                    edit_license_info: null,
                    msgs:['server returned null response']
                   }
        }

        return out;                              
    }

    async handle_modify_license_info(input:ModifyLicenseInfo.InType) : Promise<ModifyLicenseInfo.OutType> {
        const out = await query_server<ModifyLicenseInfo.InType,
                                       ModifyLicenseInfo.OutType>(input,
                                                        ModifyLicenseInfo.CMD);

        if(out == null || typeof out === 'string') {
            return {status : MsgStatus.ERROR, msgs:['null response from server']  }
        }

        return out;                              
    }

    async handle_reset_device_activation_count(input:  ResetDeviceActivationCount.InType) : Promise<    ResetDeviceActivationCount.OutType> {
        const out = await query_server<ResetDeviceActivationCount.InType,
                                       ResetDeviceActivationCount.OutType>(input,
                                                                           ResetDeviceActivationCount.CMD);

        if(out == null || typeof out === 'string') {
            return {status : MsgStatus.ERROR  }
        }

        return out;                              
    }

    
    async handle_add_free_mobile_licenses(input: AddFreeMobileLicenses.InType) : Promise< AddFreeMobileLicenses.OutType> {
        const out =
            await query_server<AddFreeMobileLicenses.InType,
                               AddFreeMobileLicenses.OutType>(input,
                                                              AddFreeMobileLicenses.CMD);

        if(out == null || typeof out === 'string') {
            if(out == null) {
                console.error(TAG+'handle_add_free_mobile_licenses: out=null');
            } else {
                console.error(TAG+'handle_add_free_mobile_licenses: out='+out);
            }
            return {status : MsgStatus.ERROR, msg:'', common_activation_code:null  }
        }

        return out;                              
    }
    
    async handle_get_all_free_mobile_licenses(input:GetFreeMobileLicenses.InType) : Promise< GetFreeMobileLicenses.OutType> {
        
        const out =
            await query_server<GetFreeMobileLicenses.InType,
                               GetFreeMobileLicenses.OutType>(input,
                                                              GetFreeMobileLicenses.CMD);

        if(out == null || typeof out === 'string') {
            return {status : MsgStatus.ERROR, license_infos:[]  }
        }

        return out;                              
    }

    async handle_send_mobupgrade_invites(input: SendMobUpgradeInvites.InType) : Promise<SendMobUpgradeInvites.OutType>
    {
        const out =
            await query_server<SendMobUpgradeInvites.InType,
                               SendMobUpgradeInvites.OutType>(input,
                                                              SendMobUpgradeInvites.CMD);

        if(out == null || typeof out === 'string') {
            if(out == null) {
                console.error(TAG+'handle_send_mobupgrade_invites: out=null');
            } else {
                console.error(TAG+'handle_send_mobupgrade_invites: out='+out);
            }
            return SendMobUpgradeInvites.ERROR_OUT;
        }

        return out;                              
    }
    
    
    async handle_get_mobupgrade_licenses(input: GetMobUpgradeableLicenses.InType,
                                         options? :{cancelExec? : ()=>boolean }) : Promise<GetMobUpgradeableLicenses.OutType>
    {
        const out =
            await query_server<GetMobUpgradeableLicenses.InType,
                               GetMobUpgradeableLicenses.OutType>(input,
                                                                  GetMobUpgradeableLicenses.CMD,
                                                                  options);

        if(out == null || typeof out === 'string') {
            if(out == null) {
                console.error(TAG+'handle_get_mobupgradeable_license: out=null');
            } else {
                console.error(TAG+'handle_get_mobupgradeable_licenses: out='+out);
            }
            return GetMobUpgradeableLicenses.ERROR_OUT;
        }

        return out;                              
    }
    
    
}
 
export function getAdminServerComm() : AdminServerComm {
    return new AdminServerComm();     
}
