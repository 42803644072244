// File: list_all_licenses.tsx
//

import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import { MainLayout} from '../utils_admin/main_layout';
import { useAdminPagesContext } from '../utils_admin/admin_pages_provider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
//import Backdrop  from '@mui/material/Backdrop';
//import  CircularProgress  from '@mui/material/CircularProgress';
import { get_session_token, LOGGER} from '../../utils_common/logger';
import { getAdminServerComm } from '../utils_admin/admin_server_comm';
import {  SearchLicensesResultState, BackLicensesTableState,
    get_search_licenses_results_state, LicensesTableState } from '../utils_admin/display_licenses/licenses_loc_states';
import {  ListAllLicensesMsg, MsgStatus } from '../../extlinks/portal_comm_types';
import { sendLocationState, useLocationState } from '../../utils_common/use_location_state';
import { OKDialog } from '../utils_admin/ok_cancel_dialog';
import { DownloadProgressDialog } from '../../utils_common/alert_dialog';
import { LicenseSorter, EMAIL_SORTER,
    SelectLicenseSorter,
    get_license_sorter
} from '../utils_admin/display_licenses/select_license_sorter';
import { decompressFromBase64 } from '../../utils_common/lzstring';
import { get_row_filter, NONE_ROW_FILTER, RowFilter, SelectFilterRows } from '../utils_admin/display_licenses/select_filter_rows';
import { AllLicensesTable } from '../utils_admin/display_licenses/all_licenses_table';
import { ADMIN } from '../../routes/paths';

const TAG='list_all_licenses.tsx: ';
const DEBUG=true;
 
type UserLicense = ListAllLicensesMsg.UserLicense;

function add_to_mp_to_list(mp: Map<string,string[]>,key:string, entry:string) : void {
    const x = mp.get(key);
    if(x === undefined) {
	mp.set(key,[entry]);
    } else {
	x.push(entry);
    }
}

function get_email_to_license_keys(licenses: ListAllLicensesMsg.UserLicense[]) : Map<string, string[]> {
    
    const mp = new Map<string,string[]>();
    for(let x of licenses) {
	for(let y of  x.ac_emails) add_to_mp_to_list(mp,y, x.license_key);
	for(let y of  x.fs_emails) add_to_mp_to_list(mp,y, x.license_key);
	for(let y of  x.as_emails) add_to_mp_to_list(mp,y, x.license_key);
	if(x.portal_email) add_to_mp_to_list(mp, x.portal_email, x.license_key);
    }
    
    return mp;    
}

export function ListAllLicenses() : React.ReactElement {

    //const page_num_ref = React.useRef<number>(0);
    const set_page_num_from_loc_state = React.useRef<boolean>(true);

    const [ licenseSorter, setLicenseSorter ] = React.useState<LicenseSorter>(EMAIL_SORTER);
    
    // this has subsidiary effect of forcing a re-render of the table
    // upon page change from a page number link
    // (otherwise wont re-render because url is unchangd)
    const [ pageNum, setPageNum] = React.useState<number|null>(null);

    const loc_state = useLocationState<LicensesTableState| BackLicensesTableState>();

    React.useEffect( ()=> {
	if(loc_state !=null && loc_state.LicensesTableState_===undefined) {
	    console.error(TAG+'loc_state.LicensesTableState_==undefined');
	}
    },[]);

    React.useEffect(()=>{
	console.log('***useEffect***');
    },[]);
    
    const cancel = React.useRef<boolean>(false);

    const setCancel = ():void=> {
	cancel.current=true;
    }
    
    const [ rowFilter, setRowFilter ] = React.useState<RowFilter>(NONE_ROW_FILTER);
    
    const [ openServerErrorDialog, setOpenServerErrorDialog] = React.useState<boolean>(false);
    
    const [ openNoResultsDialog, setOpenNoResultsDialog] = React.useState<boolean>(false);

    const [ openDownloadingDialog, setOpenDownloadingDialog] = React.useState<boolean>(false);    
    
    const mainStateContext = useAdminPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;

    const [ isWaiting,setIsWaiting] = React.useState<boolean>(false);
    
    const server = getAdminServerComm();

    const [filteredUserLicenses, setFilteredUserLicenses ] = 
	React.useState<ListAllLicensesMsg.UserLicense[]|null>(null);
    
    const [allUserLicenses, setAllUserLicenses ] = 
	React.useState<ListAllLicensesMsg.UserLicense[]|null>(null);    

    const refresh_cnt = React.useRef<number>(0);
    
    //const x = true;
    const refresh = (force:boolean=false):void => {

	if(! force && refresh_cnt.current!==0) return;
	refresh_cnt.current++;
	
	if(DEBUG) console.log(TAG+'***entering refresh');

	const cancelExec = () : boolean => {
	    return cancel.current;
	}
	
	cancel.current = false;
	
	setIsWaiting(true);	
	setOpenDownloadingDialog(true);	
	const session_token = get_session_token();	
	server.handle_list_all_user_licenses({
	    session_token,
	    min_range:0,
	    max_range:null
	},
	    { cancelExec }
	    
	).then(
	    (result:ListAllLicensesMsg.OutType):void=> {
		console.log(TAG+'return from server.handle_list_all_user_infos');
		if(result.status === MsgStatus.OK) {
		    const user_licenses_in_range_json =
			decompressFromBase64(result.user_licenses_in_range_compress_b64);
		    if(user_licenses_in_range_json==null) {
			console.error('user_licenses_in_range_json==null');
			return;
		    }
		    //console.log('user_licenses_in_range_json='+user_licenses_in_range_json);
		    const user_licenses_in_range = JSON.parse(user_licenses_in_range_json) as UserLicense[];
		    setAllUserLicenses(user_licenses_in_range);
		    const y = [...licenseSorter.sort(user_licenses_in_range.filter((x)=>rowFilter.filter(x)))];
		    setFilteredUserLicenses(y);
		    //if(DEBUG) console.log(TAG+'refresh: result.num_all_user_licenses='+result.num_all_user_licenses);
		    if(DEBUG) console.log(TAG+'refresh: result.user_licenses_in_range.length='+
			user_licenses_in_range.length);
		} else {
		    console.error(TAG+'error: msgs=');
		    for(let msg of result.msgs) {
			console.error(JSON.stringify(msg.split('\n'),null,2));
			LOGGER.error(msg.replace(/\n/g,''));
		    }
		}
		setOpenDownloadingDialog(false);
		setIsWaiting(false);		    
	    }
	).catch( (error)=> {
	    setIsWaiting(false);
	    setOpenDownloadingDialog(false);	    
	    LOGGER.error(TAG+'refresh: error='+error);
	});
	
    }

    
    const cnt = React.useRef<number>(0);

    React.useEffect(()=> {    
	if(cnt.current===0) {
	    LOGGER.log_event('LIST ALL LICENSES','list_all_licenses.tsx');
	}
	cnt.current++;
    },[]);


    const set_filter_sorter_from_back_state = ():void=> {

	console.log(TAG+'set_filter_sorter_from_back_state: loc_state=',loc_state);
	
	if(loc_state !=null && 'BackLicensesTableState_' in loc_state) {
	    console.log(TAG+'set_filter_sorter_from_back_state');
	    const state = loc_state;
	    if(state.rowFilterName && state.licenseSorterName) {
		const row_filter = get_row_filter(state.rowFilterName);
		const license_sorter = get_license_sorter( state.licenseSorterName);
		if(row_filter && license_sorter) {
		    setRowFilter(row_filter);
		    setLicenseSorter(license_sorter);
		}
	    }
	    if(loc_state.page_num != null && set_page_num_from_loc_state.current) {
		const x = loc_state.page_num; // this prevents need for double click
		setPageNum(x);
		set_page_num_from_loc_state.current=false;
		//page_num_ref.current = loc_state.page_num;
	    }
	}	
    }

    React.useEffect(()=> {
	set_filter_sorter_from_back_state();	
    },[]);
	
    // TODO: need refresh button to account for changes in license from last time
    React.useEffect(()=> {
	//console.log('page_num='+page_num.current);
 	if(loc_state !=null && loc_state.page_num !== null) {
	    const x = loc_state.page_num; // this prevents need for double click
	    setPageNum(x);  // this is needed to cause table to re-render
	    //set_page_num_from_loc_state.current = false;
	    //page_num_ref.current = loc_state.page_num;
	    if(DEBUG) console.log(TAG+'loc_state.page_num=',loc_state.page_num);
	    if(DEBUG) console.log(TAG+'PT0 set page_num=',pageNum);
	} else {
	    setPageNum(0); // this is needed to cause table to re-render
	    //page_num_ref.current = 0;
	    if(DEBUG) console.log(TAG+'PT1 set page_num=',pageNum);
	}
	
	if(loc_state !=null && loc_state.allValidUserLicenses !== undefined &&
	     loc_state.allValidUserLicenses.length>0 && 
	    loc_state.allValidUserLicenses != null) {
	    if(DEBUG) console.log(TAG+'PT3 loc_state.allValidUserLicenses.length='+
		loc_state.allValidUserLicenses.length);

	    const w = [...loc_state.allValidUserLicenses];
	    setAllUserLicenses(w);

	    setIsWaiting(true);
	    const y = [...licenseSorter.sort(w.filter((x)=>rowFilter.filter(x)))];
	    setFilteredUserLicenses(y);
	    setIsWaiting(false);		    	    
	} else if(allUserLicenses!=null) {
	    setIsWaiting(true);
	    const y = [...licenseSorter.sort(allUserLicenses.filter((x)=>rowFilter.filter(x)))];
	    setFilteredUserLicenses(y);
	    setIsWaiting(false);		    	    	    
	} else {
	    refresh();
	}

    },[])

    React.useEffect(()=> {
	
	if(loc_state !=null && loc_state.allValidUserLicenses !== undefined &&
	    loc_state.allValidUserLicenses.length>0 && 
	    loc_state.allValidUserLicenses!=null) {
	    setIsWaiting(true);
	    const w = loc_state.allValidUserLicenses;
	    setAllUserLicenses(w);
	    const y = [...licenseSorter.sort(w.filter((x)=>rowFilter.filter(x)))];
	    setFilteredUserLicenses(y);
	    if(filteredUserLicenses) {
		console.log(TAG+'PT1: filteredUserLicenses.length='+filteredUserLicenses.length)
	    }
	    setIsWaiting(false);		    	    	    
	} else if(allUserLicenses != null) {
	    setIsWaiting(true);
	    const y = [...licenseSorter.sort(allUserLicenses.filter((x)=>rowFilter.filter(x))) ];
	    setFilteredUserLicenses(y);
	    if(filteredUserLicenses) {	    
		console.log(TAG+'PT2: filteredUserLicenses.length='+filteredUserLicenses.length)
	    }
	    setIsWaiting(false);		    	    	    	    
	}
    },[licenseSorter, rowFilter]);

// MsgAlertDialogProgress

    React.useEffect( ()=> {    
	if(loc_state!=null) {
	    if(loc_state.page_num !== undefined &&
		loc_state.page_num != null) {
		const x= loc_state.page_num; // this prevents need for double click
		setPageNum(x);
		//set_page_num_from_loc_state.current=false;
	    }
	    /*
	    if(loc_state.rowFilter !== undefined) {
		setRowFilter(loc_state.rowFilter);
	    }
	    if(loc_state.licenseSorter !== undefined) {
		setLicenseSorter(loc_state.licenseSorter);
	    }*/	    
	}
    },[loc_state])
    
    const [ searchExpr, setSearchExpr ] = React.useState<string>('');
    
    const getAllEmailToLicenseKeys = () : Map<string,string[]>|null=> {

	if(allUserLicenses == null) {
	    setOpenServerErrorDialog(true);
	    return null;
	}
	
	const licenses: ListAllLicensesMsg.UserLicense[]|null = allUserLicenses;
	if(licenses==null) return null;
	
	const mp = get_email_to_license_keys(licenses);

	//console.log(TAG+'email_to_license_key.keys()=' + Array.from(mp.keys()));
	
	return mp;
    }

    const get_license_keys_of_email_matches = (expr:string) :string[]|null=> {
	
	const email_to_license_keys = getAllEmailToLicenseKeys();

	if(email_to_license_keys == null) return null;

	const license_keys_set = new Set<string>();
	
	const ws = expr.split(' ').map( (x)=>x.trim().toLowerCase() );
	for(let w of ws) {
	    if(w !== '') {
		for(let [email,license_keys] of email_to_license_keys) {
		    if(email.includes(w)) {
			for(let license_key of license_keys) {
			    license_keys_set.add(license_key);
			}
		    }
		}
	    }
	}

	return Array.from(license_keys_set);
	
    }

    const navigate = useNavigate();
    
    const searchEmails = ():void => {

	setOpenNoResultsDialog(false);
	
	const expr = searchExpr.trim();
	if(expr === '') return;

	setIsWaiting(true);

	const matching_license_keys = get_license_keys_of_email_matches(expr);
	if(matching_license_keys==null || matching_license_keys.length===0) {
	    setIsWaiting(false);
	    setOpenNoResultsDialog(true);	    
	    return;
	}

	//console.log('matching_license_keys=',matching_license_keys); 
	
	if(!allUserLicenses) {
	    setOpenServerErrorDialog(true);	    
	    return;
	}

	const matching_licenses : ListAllLicensesMsg.UserLicense[] = []
	
	for(let license_key of matching_license_keys) {
	    for(let x of allUserLicenses) {
		if(x.license_key === license_key) {
		    matching_licenses.push(x);
		}
	    }
	}

	setIsWaiting(false);
	
	if(matching_licenses.length>0) {
	    const state = get_search_licenses_results_state({matching_licenses,
		rowFilterName:rowFilter.name,
		licenseSorterName:licenseSorter.name,
		page_num:pageNum, allUserLicenses });
	    sendLocationState<SearchLicensesResultState>(ADMIN.LIST_LICENSES_EMAIL_SEARCH_URL,
		state, navigate);
	}
	
    }
    
    const theme = createTheme();
    
    return (
	<ThemeProvider theme={theme}>	
	    <CssBaseline />	      
	    <MainLayout title='Admin Console - All Licenses'>

	<>
	{filteredUserLicenses &&		
	    (<><Box sx={{ 
		    display: 'flex', flexDirection:'column',
 		    alignItems : 'center'}}>
	      
	      <Box><h2>All Licenses</h2></Box>
	      
	      <Box
		sx={{paddingLeft:'0',ml:0, mb:2,
		    display: 'flex', flexDirection:'row',
 		    alignItems : 'center',
		    justifyContent:'flex-end'}}>

		<TextField
		sx={{mt:0,mb:0}}
		size="small"
		id="email"
		label=""
		name="email"
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setSearchExpr(e.target.value.trim().toLowerCase())
		}}
		/>
	
		<Button  onClick={searchEmails}
			 size="small" sx={{ml:3, border:'solid'}}>
	      SEARCH EMAIL
		</Button>
	</Box>

	<Box sx={{mb:0}} style={{width:'100%',display:'flex', flexDirection:'row', justifyContent:'space-around'}}>

	<Box>	  
        <SelectLicenseSorter
	    sorter={licenseSorter}
	    setSorter={setLicenseSorter}/>
	</Box>

	  
	<Box textAlign="center" sx={{mt:1}}>
	  <Button size="small" 
 style={{borderRadius:'4pt',border:"1pt solid black",minWidth:'6em',
	         paddingTop:'1pt',paddingBottom:'1pt', paddingRight:'0.5em',paddingLeft:'0.5em',
	         backgroundColor:theme.palette.primary.main,
	         color:'white' }} sx={{mb:1,mr:3}}		  
		  onClick={()=>refresh(true)}>
            REFRESH
	  </Button>	      
	</Box>

	</Box>


         <Box sx={{pl:2,pr:2}} style={{ display:'flex', flexDirection:'row',
	     alignItems:'flex-end',
	     justifyContent:'space-between'}}>
              <SelectFilterRows setFilter={setRowFilter}
				filter={rowFilter}/>
	      
        </Box>	

	
	</Box>	
	    </>)
	}
	</>

	<>
       	{/* change in pageNum causes table to automatically re-render */}
	{allUserLicenses != null && filteredUserLicenses != null &&  pageNum != null &&
	    <AllLicensesTable page_num={pageNum}
			      setPageNum={setPageNum}
			      licenseSorter={licenseSorter}
			      rowFilter={rowFilter}
			      split_pages={true}
			      current_user_admin_role={mainStateContext.current_user_admin_role}
			      allUserLicenses={allUserLicenses}
			      filteredUserLicenses={filteredUserLicenses}/>
	}	      
	</>

	{/*
	<Backdrop invisible={true} open={mainPagesIsLoading || isWaiting}
		  sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	  <CircularProgress/>
	</Backdrop>
	*/}

	<>
	{ (mainPagesIsLoading || isWaiting) &&
	    <DownloadProgressDialog msg={'Downloading data. This may take a few minutes.'} setCancel={setCancel}/>
	}
	</>

	{/*
	<OKDialog onClose={()=>setOpenDownloadingDialog(false)}
		  open={openDownloadingDialog} title='Download data. This will take a few minutes.'/>
	*/}
		
	<OKDialog onClose={()=>setOpenNoResultsDialog(false)}
		  open={openNoResultsDialog} title='No results found.'/>

	<OKDialog onClose={()=>setOpenServerErrorDialog(false)}
		  open={openServerErrorDialog} title='Server error. Try again by pressing refresh button.'/>	  
	  
	    </MainLayout>

	</ThemeProvider>
  );
    
}
