// File: RequireAuth.tsx
//

import React from 'react';
import { Outlet, useLocation, NavLink } from 'react-router-dom';
import { useAuthStatus } from '../auth_provider/use_auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {
    //OLDPUB_EMAIL_NOT_VERIFIED_ENDING_URL,
    OLDPUB_SIGNIN_ENDING_URL } from '../extlinks/portal_comm_types';
import { using_emulator } from '../config/firebase_config';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const TAG='PubRequireAuth.tsx: ';

const DEBUG=false;

const theme = createTheme();

export const PubRequireAuth =
    () : React.ReactElement => {
    
    const {user, refresh} = useAuthStatus();
    const location = useLocation();

    React.useEffect( ()=> {
	refresh();	
    },[]);
	
    React.useEffect( ()=> {
	refresh();		
	if(user) {
	    if(DEBUG) {
		console.log(TAG+'user != null')
		console.log(TAG+'user.emailVerified='+user.emailVerified);
	    }
	} else {
	    if(DEBUG ||  using_emulator()) {	    
		console.log(TAG+'user == null')
	    }
	}
    }, [user]);

    return (
	<>
	{ user == null &&
  	    ( <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs" sx={{p:10}}>
		<CssBaseline />
		<Box style={{display:'flex', alignItems:'center',
		    justifyContent:'center',
		    flexDirection:'column'}}>
                    <Typography variant='h5' align='center'>
		   Not logged in.
                    </Typography>
		</Box>
	      </Container>
	      </ThemeProvider>)
	}
	
	{ user != null &&  ! user.emailVerified &&
 	    (
	      <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs"  sx={{p:10}}>
		<CssBaseline />
		Email was not verified.
		  <NavLink to={OLDPUB_SIGNIN_ENDING_URL}
			    state={{ from : location }}>
		    Sign In Again
		  </NavLink>		    
	        </Container>
	     </ThemeProvider>)
	}
	
	{ user != null && user.emailVerified &&
		(<Outlet />)	  
	}
	</>
    );
    
}


